import React, { useEffect } from 'react';
import { View, StyleSheet, Text,Font,Image } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'
import Comments from './Comments'
import Specimen from './Specimen'
import Result from './Result'
import MedicineHeader from './MedicineHeader'
import CovidPDF from '../Covid_PDF/CovidTemplate'
import { keys } from '@material-ui/core/styles/createBreakpoints';
import InvoiceTitleNewGroup from './InvoiceTitleNewGroup'
const tableRowsCount = 11;

Font.register({ family: "Inconsolata", src: "http://fonts.gstatic.com/s/inconsolata/v15/7bMKuoy6Nh0ft0SHnIGMuaCWcynf_cDxXwCLxiixG1c.ttf", });

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    fontFamily: "Helvetica",
    borderColor: 'black',
  },
  subReportTitle: {
    color: 'black',
        fontSize: 9,
        fontFamily:'Helvetica-Bold',
        paddingRight:5,
        paddingTop:5,
        textAlign: 'left'
  },
  GermTitle: {
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  SusProfileTitle:{
    color: 'black',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  MedicineGroupName:{
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left'
  },
  textSup:{
color:"red",
verticalAlign:'superscript' ,
// lineHeight:24,
fontFamily:"Inconsolata"
}

});


class InvoiceItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoice,
        // 9-march 
        pic:props.pic,
      line: 2,
      page: 1,
      CrossCheck: {},
      NextPage: 0,
      Garbage: [],
      counter: 0,
      page:0,
      DefaultComments:{
        "Cobas C311":"Please note:- Test (s) are performed on state of the art Cobas 6000, fully automated Modular System, from Roche Diagnostics, Switzerland.",
      //  feb 2024 aik he machine k test aik sth aaen jaisy cobas 311 and e 411 k aaty hn ye es k lye  Architect i1000 SR
        "Architect i1000 SR":"Test (s) are performed on state of the art Architect i1000 SR, fully automated immunoassay system based on chemiluminescent microparticle immunoassay (CMIA), from Abbott Diagnostics, USA.",
        
        // "Cobas C311":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        // "Cobas C111":"Method/Analyzer: Cobas C311, fully automated chemistry analyzer, from Roche Diagnostics, Switzerland.",
        // "Cobas e411":"Method/Analyzer: Cobas e 411, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
        // "Cobas C6000":"Method/Analyzer: Cobas e 6000, fully automated Electrochemiluminescence immunoassay (ECLIA) based analyzer, from Roche Diagnostics, Switzerland.",
         // 11-july-2023 new machine add en dono me se koi aik extra hogi
      "IDS-iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
      "IDS - iSYS": " Please note:- Test (s) are performed on state-of-the-art IDS-iSYS, fully automated specialty Chemiluminescent Immunoassay analyzer (CLIA), from Immunodiagnostic Systems Holdings PLC, UK.",
       
      "Cobas C111": "Please note:- Test (s) are performed on state of the art Cobas 6000, fully automated Modular System, from Roche Diagnostics, Switzerland.",
        "Cobas e411": "Please note:- Test (s) are performed on state of the art Cobas 6000, fully automated Modular System, from Roche Diagnostics, Switzerland.",
        "Cobas C6000": "Please note:- Test (s) are performed on state of the art Cobas 6000, fully automated Modular System, from Roche Diagnostics, Switzerland.",
        "Blue Diver Instrument":"Method/Analyzer: Blue Diver Instrument, automated Immunodot analyzer, from D-tek, Belgium.",
        "BC - 5000":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "BC - 5150":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Cobas U411":"Method/Analyzer: Cobas u 411, automated Urine analyzer, from Roche Diagnostics, Switzerland.",
        "Cube 30 Touch":"Method/Analyzer: Cube 30 TOUCH, Automatic instrument for ESR, from Diesse Diagnostica Senese, Italy.",
        "GeneXpert":"Method/Analyzer: GeneXpert ® PCR system by Cepheid, USA.",
        "BC6200":"Method/Analyzer: Mindray BC-6200, fully automated 7 part differential hematology analyzer.",
        "Compact X, Behnk Elektronik":"Method/Analyzer: Thrombolyzer Compact X, , fully automated coagulation analyzer, from Behnk Elektronik GmbH & Co. Germany.",
        "FANhp UBT":"Method/Analyzer: FANhp, An automated Urea Breath Testing analyzer, from Fischer Analysen Instrumente (FAN), Germany.",
        "MiniCap Sebia":"Method/Analyzer: MINICAP FLEX PIERCING, fully automated Capillary Electrophoresis System, from Sebia, France.",
        "Chorus Trio":"Method/Analyzer: Chorus TRIO Instrument, automated ELISA based immunoassay analyzer, from Diesse Diagnostica Senese Spa, Italy.",
        "Manual":"",
        "OST":"",
        "Microlab 300":"",
        "Manual ELISA":"Method/Analyzer: PR-4100/PW-40, An Immunoassay System, from Bio-Rad, USA.",
        "Alegria Instrument":"Method/Analyzer: Alegria Instrument, automated ELISA based immunoassay analyzer, from Orgentec Diagnostika GmbH, Germany.",
        "HG SWIFT":"Method/Analyzer: HG Swift, A loop mediated iso thermal amplification (LAMP), from Hibergene, Ireland.",
        "VIDAS":"Method/Analyzer: Automated immunoassay analyzer (VIDAS) from Biomerieux SA, France."
      },
      Machine:"",
      singleTestCounter:0,
      MachineCommentsToShow:[],
      ShowMachineComments:true,
      // 27-feb-2025 (1/3)
      tempArray:[]
    }
  }

  render() {

    return <View
    //  style={{backgroundColor:"grey"}}
      >
    
      {/* {(Object.keys(this.state.data.list).map((key) => { */}
      {(Object.keys(this.state.data.list).map((key,index) => {

  // 27-feb-2025 (2/3) 3202987,9802942 issue in 25-oh-vitam D last pe aana chahye tha es lye reorderList lga k es pe condition lgae hai agr  Architect i1000 SR hai aur us me 25-OH Vitamin D hai to last pe le jae 25-feb-2025 backup es se phly wala hai
  if(key=="Architect i1000 SR"){
    console.log("yess wohi hai")

    console.log(this.state.data.list[key])
    const reorderedList = [
      ...this.state.data.list[key].filter(item => item.test_name !== '25-OH Vitamin D Total'),
      ...this.state.data.list[key].filter(item => item.test_name === '25-OH Vitamin D Total')
    ];
    console.log(reorderedList)
    this.state.tempArray.push(reorderedList)

console.log(this.state.tempArray)


  }
      var shiftMargin = 0
      var totalTests = 0
      this.state.line = this.state.line + 2
     
      var subGroupTitle = {}

      this.state.data.list[key].map((item) => {
        totalTests = totalTests + item.rowHeight
      })

      var FirstBox = this.state.line + this.state.data.list[key][0].rowHeight + 2

      if (FirstBox > 28) {
        this.state.line = 0
        this.state.NextPage = 1


      } else {
        this.state.NextPage = 0


      }
      if (key.includes("Complete") && this.state.data.list[key][0].prev1.length > 4) {
        this.state.line = this.state.line - 8
      }
      if (this.state.NextPage == 0) {

        this.state.Garbage.push(1)
      } else {
        this.state.Garbage = []
      }
      if (this.state.Garbage.length == 3) {
        this.state.NextPage = 1
        this.state.line = 0
      }

      if ((key.includes("Complete Blood Picture") || key.includes("Urine Routine Examination")) && this.state.counter > 0) {
        this.state.NextPage = 1
        this.state.line = 0
      }
      // Machine
    
      this.state.ShowMachineComments=false
      if(this.state.Machine!=this.state.data.Machine[key]){
        this.state.ShowMachineComments=true
        this.state.Machine=this.state.data.Machine[key]
        
      }
      console.log("Machine : ")
      console.log(this.state.data.Machine[key])
      var pasteMachineComm=this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]
      
      this.state.MachineCommentsToShow.push(pasteMachineComm)
   
      console.log(this.state.MachineCommentsToShow)
      // Germ Counter
      var GermCounter=0
      console.log(key)
      console.log(shiftMargin)
      console.log(this.state.NextPage)
      console.log(this.state.counter)
      console.log(Object.keys(this.state.data.Germs).length)
      console.log("Comments Flag")
      console.log(this.state.ShowMachineComments)
      console.log(this.state.data.MachineComments[key])
      this.state.counter++

      // Test counter
      console.log("Test List Length = ")
      console.log(Object.keys(this.state.data.list).length)
      if(this.state.data.list[key].length==1){
        this.state.singleTestCounter++
      }else{
        this.state.singleTestCounter=0
      }
      
     console.log("Machine Comments = ")
     console.log(this.state.data.comments[key])
      console.log(this.state.data.comments[key].trim().length)

      return <View>
                
          {
                key.includes("Covid") ||  key.includes("COVID-19 Rapid Antigen Test (RAT)")  ?
              <View>
               
              </View>
              :
              <View >
               
                <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: "100%",
                      fontSize: 7,
                      paddingLeft: 10,
                      paddingRight: 10,
                     // key.includes("Semen Analysis")? -15: this line add for 5301410 ye 2 pages pe aa rha tha Recp personal msg 9-jan-2023 otherwise -10 he kam kr rha hai sab k lye Logo me b add kiya
                      // marginTop:key.includes("Complete") ? -60 :-10
                      // marginTop:key.includes("Complete") ? -60 :key.includes("Semen Analysis")? -15:-10
                       // 10-aug-2023 -10 ki -12 thora oper kiya hai 1301825 ye signature 2nd page chala gya tha aur niche signature ko marginTop -5 kiya hai
                       marginTop:key.includes("Complete") ? -60 :key.includes("Semen Analysis")? -15:this.state.data.invoice_id=="1902559"?-29:-12
                    }}
                    >
                  
                  
                 </View>

              <View style={styles.tableContainer} >

                <View style={{
                  padding: 10

                }}>
                  {
                    this.state.counter > 1 ? <View break={true} key={key + "1"}></View> : null
                  }

   {/* for display new group title on every title 3 nov 2022*/}
                {/* { ((this.state.data.list[key].length==1 && (key==this.state.data.list[key][0].test_name) ) && this.state.data.list[key][0].newValueGroupTitle==null || key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitleNewGroup title={this.state.data.list[key][0].newValueGroupTitle} />} */}
              {/* for display new group title on every page 5 nov 2022* */}
              {/* {  this.state.data.GroupTitleNew==null ? null : <InvoiceTitleNewGroup title={this.state.data.GroupTitleNew} />} */}
              {/* //  feb 2024 aik he machine k test aik sth aaen jaisy cobas 311 and e 411 k aaty hn ye es k lye  Architect i1000 SR  ye add kiya key=="Architect i1000 SR"*/}
             
                {/* { ((this.state.data.list[key].length==1 && (key==this.state.data.list[key][0].test_name) ) || key=="Cobas e411" || key=="Cobas C311" ) ? null : <InvoiceTitle title={key} />} */}
                { ((this.state.data.list[key].length==1 && (key==this.state.data.list[key][0].test_name) ) || key=="Cobas e411" || key=="Cobas C311"||key=="Architect i1000 SR"  ) ? null : <InvoiceTitle title={key} />}
                 
                  {
                    (this.state.data.list[key][0].subGroupTitle != "" && this.state.data.list[key][0].subGroupTitle != "-" && this.state.data.list[key][0].subGroupTitle[this.state.data.list[key][0].subGroupTitle] == undefined)
                      ?
                      null 
                    :
                    //  <InvoiceTableHeader data={this.state.data.list[key][0]} title={key}  />
                    <></>
                  }
                                <InvoiceTableHeader data={this.state.data.list[key][0]} />
  
                

                  {
//                     this.state.data.list[key].map(item => {
//                       this.state.line = this.state.line + item.rowHeight
//                       var showSubHeadingBoolean = false
//                       console.log(subGroupTitle[item.subGroupTitle])
//                       if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
//                         showSubHeadingBoolean = true
//                         subGroupTitle[item.subGroupTitle] = item.subGroupTitle
//                         console.log("Sub Title")
//                         console.log(subGroupTitle)
//                         this.state.line = this.state.line + 2
//                       }

//                       var space = this.state.line > 28
//                       if (this.state.line > 28) {
//                         this.state.line = 0
//                       }



//                       return <View >

//                             <View break={true} key={item.test_name + "1"}>

//                             </View> 
//                                    {/* 9-march */}
//                                    {console.log("check show sub heading Boolen") }
//                             {console.log(showSubHeadingBoolean) }
//                             {console.log("check show sub heading Boolen") }
//                             {console.log(subGroupTitle) }

//                             {
//           // 13-DEC-2024 new test add same as prev by sir najam 

//                           // showSubHeadingBoolean==true && subGroupTitle.Conclusion=="Conclusion" && key.includes("Hydrogen (H2) Breath test for Lactose Intolerance")?<>
//                           showSubHeadingBoolean==true && subGroupTitle.Conclusion=="Conclusion" && (key.includes("Hydrogen (H2) Breath test for Lactose Intolerance")||key.includes("Hydrogen (H2) Breath test for Fructose Intolerance"))?<>
            
//             <Text style={{ fontSize:4,marginLeft:140,marginTop:2}}>H2(ppm)</Text>

                   
//                             <View style={{
//                       width: "100%",
//                       paddingTop: 2,
//                       marginLeft:140

//                     }}>
//                     <Image style={{ width: 280,
//         height: 140}}  src={this.state.pic}></Image>
//                     </View>
//             <Text style={{ fontSize:4,marginLeft:430,marginTop:-8}}>Time(min)</Text>

//                     </>:<></>}
                         
//                         {
//                           showSubHeadingBoolean ?
//                           <View>
//                             <View style={{
//                               flexDirection: 'row',
//                               justifyContent:'left'
//                             }} >
//                               <Text style={styles.subReportTitle}>

//                                 {item.subGroupTitle}

//                               </Text>
//                               </View>
//                               {/* {
//                                 key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"
//                                 ?
//                                 null
//                                 :
//                                 <InvoiceTableHeader data={this.state.data.list[key][0]} />

//                               } */}
                             
                         
//                            </View>
//                             :
//                             <Text></Text>
//                         }
                        
//                         <View style={{
//                           flexDirection: 'row',
//                           alignItems: 'center',
//                           width: "100%",
//                           fontSize: 7,
//                          // 24-july-2023
//         // marginTop: 0.5,
//  // 27-july-2023
//  borderColor:"#c5c5c5",
//  borderStyle:"solid",

//         marginTop: -0.5,
//                           borderWidth: 0.5
//                         }} key={item.test_name}>

// <Text style={{

// width: key=="Hormonal Profile for Female"  ? '15%' : '20%',
// textAlign: 'left',
// paddingTop: 2,
// paddingLeft: 8,
// fontFamily: this.state.data.list[key].length==1 || (key=="Cobas e411" || key=="Cobas C311") ? "Helvetica-Bold" : "Helvetica",
// height: '100%',
// textAlign: 'left',
// fontSize: 7
// }}>{item.test_name}</Text>
// <Text style={{

// // width:  key=="Hormonal Profile for Female" ? '20%' : '20%',
// // // textAlign: 'center',
// // textAlign:item.result.includes("Calcium oxalate monohydrate")?"left":"center",

// // // borderLeftColor: 'black',
// // // borderLeftWidth: 0.5,
// // borderLeftColor: '#c5c5c5',
// // borderLeftWidth: 0.5,
// // paddingTop: 2,
// // fontWeight: '',
// // fontSize: 8,
// // height: '100%',
// //  // 20-june-2023 7 tha ab 8 kiya
// // paddingLeft: 8,
// // fontFamily: "Helvetica-Bold",
// //  // 20-june-2023 add padding
// // padding:5

// width:  key=="Hormonal Profile for Female" ? '20%' : '20%',
// // textAlign:"center",
// // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(1/3)
// textAlign:item.result.includes("Calcium oxalate monohydrate")?"left":"center",
// // borderLeftColor: 'black',
// // borderLeftWidth: 0.5,
// borderLeftColor: '#c5c5c5',
// borderLeftWidth: 0.5,
// paddingTop: 2,
// fontWeight: '',
// fontSize: 8,
// height: '100%',
//  // 20-june-2023 7 tha ab 8 kiya
// //  paddingLeft:8,
// // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(2/3)
// paddingLeft:item.result.includes("Calcium oxalate monohydrate")?0: 8,
// fontFamily: "Helvetica-Bold",
//  // 20-june-2023 add padding
// //  padding:5,
// // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(3/3)
// //9-march-2024
//           // 13-DEC-2024 new test add same as prev by sir najam 

// padding:key=="Hydrogen (H2) Breath test for Lactose Intolerance"||key=="Hydrogen (H2) Breath test for Fructose Intolerance"?0:item.result.includes("Calcium oxalate monohydrate")?4:5,
// paddingTop:key=="Hydrogen (H2) Breath test for Lactose Intolerance"||key=="Hydrogen (H2) Breath test for Fructose Intolerance"?0:5


// }}>{item.result}</Text>
// <Text style={{

// width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '25%' :  key=="Hormonal Profile for Female" ? '25%' : '20%',
// height: '100%',
// textAlign: 'left',
// // borderLeftColor: 'black',
// // borderLeftWidth: 0.5,
// borderLeftColor: '#c5c5c5',
// borderLeftWidth: 0.5,
// paddingTop: 2,
// fontFamily: "Helvetica",
// fontSize: 7,
// paddingLeft: 8,

// }}>
//   {console.log("ref range show in 345",item.ref_range)}
//   {/* this changes for report 3501378 ref range me - ni aa rhi sir najam shared 31-dec phly srf ye tha {item.ref_range=="" ? "-" : item.ref_range} */}
// {/* 24-jan-2025 */}
//  {/* {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range=="" ? "-" : item.ref_range} */}

//  {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range.includes("135")&&item.ref_range.includes("392")?"135 - 392":item.ref_range.includes("2.0")&&item.ref_range.includes("3.6")?"2.0 - 3.6":

// // 8302796 me koi aur parameter thy us ki jga 12-45 aa rhy thy thats why comment kr dya 12-feb-2025
// //  item.ref_range.includes("12")&&item.ref_range.includes("45")?"12 - 45":
//  item.ref_range=="" ? "-" : item.ref_range}

//   {/* {item.ref_range=="" ? "-" : item.ref_range} */}
// </Text>
//                           <Text style={{
//                             height: '100%',
//                             width: '10%',
//                             textAlign: 'left',
//                             // borderLeftColor: 'black',
//                             // borderLeftWidth: 0.5,
//                             borderLeftColor: '#c5c5c5',
//                             borderLeftWidth: 0.5,
//                             paddingTop: 2,
//                             fontFamily: "Helvetica",
//                             fontSize: 7,
//                             // this condition apply for 1901338 sir asim 24-dec unit long hai line k sth touch ho rha phly 8 tha
//                             // paddingLeft:item.unit=="ml/min/1.73m2"?3: 8,
//                             paddingLeft:8,
//                             flexGrow:1
                          



//                           }}>
//                             {/*  this condition apply for 1901338 sir asim 24-dec 2 power me nazar aae */}
//                             {/* {item.unit==""? "-" :item.unit=="ml/min/1.73m2"?item.unit.slice(0,-1)+"^2":  item.unit} */}

//                             {item.unit==""? "-" : item.unit}
                           



//                             </Text>

//                           <Text style={{

//                             width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
//                             textAlign: 'left',
//                             // borderLeftColor: 'black',
//                             // borderLeftWidth: 0.5,
//                             borderLeftColor: '#c5c5c5',
//                             borderLeftWidth: 0.5,
//                             paddingTop: 2,
//                             height: '100%',
//                             fontFamily: "Helvetica",
//                             fontSize: 6,
//                             paddingLeft: 5,


//                           }}>{item.prev1=="" ? "-" : item.prev1}</Text>
//                           <Text style={{

//                             width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
//                             height: '100%',
//                             textAlign: 'left',
//                             // borderLeftWidth: 0.5,
//                              // 27-july-2023
//                             // borderLeftWidth: 0.5,
//                             borderLeftColor: '#c5c5c5',
//                             borderLeftWidth: 0.5,
//                             paddingTop: 2,
//                             fontSize: 6,
//                             fontFamily: "Helvetica",
//                             textOverflow: 'auto',
//                             paddingLeft: 8

//                           }}>{item.prev2=="" ? "-": item.prev2}</Text>

//                         </View>


//                       </View>
//                     })
 (key=="Architect i1000 SR"?
                      this.state.tempArray[0].map(item => {
                        console.log(item)
                        console.log("aamirnawazbhatti")
                        this.state.line = this.state.line + item.rowHeight
                        var showSubHeadingBoolean = false
                        console.log(subGroupTitle[item.subGroupTitle])
                        if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
                          showSubHeadingBoolean = true
                          subGroupTitle[item.subGroupTitle] = item.subGroupTitle
                          console.log("Sub Title")
                          console.log(subGroupTitle)
                          this.state.line = this.state.line + 2
                        }
  
                        var space = this.state.line > 28
                        if (this.state.line > 28) {
                          this.state.line = 0
                        }
  
  
  
                        return <View >
  
                              <View break={true} key={item.test_name + "1"}>
  
                              </View> 
                              {/* 9-march */}
                              {console.log("check show sub heading Boolen") }
                              {console.log(showSubHeadingBoolean) }
                              {console.log("check show sub heading Boolen") }
                              {console.log(subGroupTitle) }
                              {/* // 13-DEC-2024 new test add same as prev by sir najam  */}
  
                              {
                            showSubHeadingBoolean==true && subGroupTitle.Conclusion=="Conclusion" && (key.includes("Hydrogen (H2) Breath test for Lactose Intolerance")||key.includes("Hydrogen (H2) Breath test for Fructose Intolerance"))?<>
              <Text style={{ fontSize:4,marginLeft:140,marginTop:2}}>H2(ppm)</Text>
  
                            {/* <View style={{ width: 400, height: 30, marginTop: 10 }}>
              <Text style={{ textAlign: 'center' }}>Months</Text>
            </View> */}
            {/* <View style={{ width: 30, height: 200, position: 'absolute', left: 20, top: 100, transform: 'rotate(-90deg)' }}>
              <Text style={{ textAlign: 'center' }}>H2(ppm)</Text>
            </View> */}
                              <View style={{
                        width: "100%",
                        paddingTop: 2,
                        marginLeft:140
  
                      }}>
                      <Image style={{ width: 280,
          height: 140}}  src={this.state.pic}></Image>
                      </View>
              <Text style={{ fontSize:4,marginLeft:430,marginTop:-8}}>Time(min)</Text>
  
                      </>:<></>}
                          {
                            // 18-mar-2025 7402988 sir asim msg in group sub heading show na ho es ki ku k main heading already aa rhi hai
                            // showSubHeadingBoolean ?
                          showSubHeadingBoolean && item.subGroupTitle!="Bilirubin Profile (TB,DB,IDB)" ?

                            <View>
                              <View style={{
                                flexDirection: 'row',
                                justifyContent:'left'
                              }} >
                                <Text style={styles.subReportTitle}>
  
                                  {item.subGroupTitle}
  
                                </Text>
                                </View>
                                {/* {
                                  key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"
                                  ?
                                  null
                                  :
                                  <InvoiceTableHeader data={this.state.data.list[key][0]} />
  
                                } */}
                               
                           
                             </View>
                              :
                              <Text></Text>
                          }
                          
                          <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            fontSize: 7,
                           // 24-july-2023
          // marginTop: 0.5,
   // 27-july-2023
   borderColor:"#c5c5c5",
   borderStyle:"solid",
  
          marginTop: -1,
                            borderWidth: 1
                          }} key={item.test_name}>
  
  <Text style={{
  
  width: key=="Hormonal Profile for Female"  ? '15%' : '20%',
  textAlign: 'left',
  paddingTop: 2,
  paddingLeft: 8,
  fontFamily: this.state.data.list[key].length==1 || (key=="Cobas e411" || key=="Cobas C311") ? "Helvetica-Bold" : "Helvetica",
  height: '100%',
  textAlign: 'left',
  fontSize: 7
  }}>{item.test_name}</Text>
  <Text style={{
  
  width:  key=="Hormonal Profile for Female" ? '20%' : '20%',
  // textAlign:"center",
  // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(1/3)
  textAlign:item.result.includes("Calcium oxalate monohydrate")?"left":"center",
  // borderLeftColor: 'black',
  // borderLeftWidth: 0.5,
  borderLeftColor: '#c5c5c5',
  borderLeftWidth: 1,
  paddingTop: 2,
  fontWeight: '',
  fontSize: 8,
  height: '100%',
   // 20-june-2023 7 tha ab 8 kiya
  //  paddingLeft:8,
  // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(2/3)
  paddingLeft:item.result.includes("Calcium oxalate monohydrate")?0: 8,
  fontFamily: "Helvetica-Bold",
   // 20-june-2023 add padding
  //  padding:5,
  // 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(3/3)
  //9-march-2024
            // 13-DEC-2024 new test add same as prev by sir najam 
  
  padding:key=="Hydrogen (H2) Breath test for Lactose Intolerance"||key=="Hydrogen (H2) Breath test for Fructose Intolerance"?0:item.result.includes("Calcium oxalate monohydrate")?4:5,
  // paddingTop:5
  
  }}>{item.result}</Text>
  <Text style={{
  
  width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '25%' :  key=="Hormonal Profile for Female" ? '25%' : '20%',
  height: '100%',
  textAlign: 'left',
  // borderLeftColor: 'black',
  // borderLeftWidth: 0.5,
  borderLeftColor: '#c5c5c5',
  borderLeftWidth: 1,
  paddingTop: 2,
  fontFamily: "Helvetica",
  fontSize: 7,
  paddingLeft: 8,
  
  }}>
    {console.log("ref range show in 345",item.ref_range)}
    {/* this changes for report 3501378 ref range me - ni aa rhi sir najam shared 31-dec phly srf ye tha {item.ref_range=="" ? "-" : item.ref_range} */}
    {/* 24-jan-2025 */}
   {/* {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range=="" ? "-" : item.ref_range} */}
  
   {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range.includes("135")&&item.ref_range.includes("392")?"135 - 392":item.ref_range.includes("2.0")&&item.ref_range.includes("3.6")?"2.0 - 3.6":
  // 8302796 me koi aur parameter thy us ki jga 12-45 aa rhy thy thats why comment kr dya 12-feb-2025
  //  item.ref_range.includes("12")&&item.ref_range.includes("45")?"12 - 45":
   item.ref_range=="" ? "-" : item.ref_range}
  
    {/* {item.ref_range=="" ? "-" : item.ref_range} */}
  </Text>
                            <Text style={{
                              height: '100%',
                              width: '10%',
                              textAlign: 'left',
                              // borderLeftColor: 'black',
                              // borderLeftWidth: 0.5,
                              borderLeftColor: '#c5c5c5',
                              borderLeftWidth: 1,
                              paddingTop: 2,
                              fontFamily: "Helvetica",
                              fontSize: 7,
                              // this condition apply for 1901338 sir asim 24-dec unit long hai line k sth touch ho rha phly 8 tha
                              // paddingLeft:item.unit=="ml/min/1.73m2"?3: 8,
                              paddingLeft:8,
                              
                              
                              flexGrow:1
                            
  
  
  
                            }}>
                              {/*  this condition apply for 1901338 sir asim 24-dec 2 power me nazar aae */}
                              {/* {item.unit==""? "-" :item.unit=="ml/min/1.73m2"?item.unit.slice(0,-1)+"^2":  item.unit} */}
  
                              {item.unit==""? "-" : item.unit}
                             
  
  
  
                              </Text>
  
                            <Text style={{
  
                              width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                              textAlign: 'left',
                              // borderLeftColor: 'black',
                              // borderLeftWidth: 0.5,
                              borderLeftColor: '#c5c5c5',
                              borderLeftWidth: 1,
                              paddingTop: 2,
                              height: '100%',
                              fontFamily: "Helvetica",
                              fontSize: 6,
                              paddingLeft: 5,
  
  
                            }}>{item.prev1=="" ? "-" : item.prev1}</Text>
                            <Text style={{
  
                              width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                              height: '100%',
                              textAlign: 'left',
                              // borderLeftWidth: 0.5,
                               // 27-july-2023
                              // borderLeftWidth: 0.5,
                              borderLeftColor: '#c5c5c5',
                              borderLeftWidth: 1,
                              paddingTop: 2,
                              fontSize: 6,
                              fontFamily: "Helvetica",
                              textOverflow: 'auto',
                              paddingLeft: 8
  
                            }}>{item.prev2=="" ? "-": item.prev2}</Text>
  
                          </View>
  
                        
                        </View>
                        
                      })
                      :
                 
                    this.state.data.list[key].map(item => {
                      console.log(item)
                      console.log("aamirnawazbhatti")
                      this.state.line = this.state.line + item.rowHeight
                      var showSubHeadingBoolean = false
                      console.log(subGroupTitle[item.subGroupTitle])
                      if (item.subGroupTitle != "" && item.subGroupTitle != "-" && subGroupTitle[item.subGroupTitle] == undefined) {
                        showSubHeadingBoolean = true
                        subGroupTitle[item.subGroupTitle] = item.subGroupTitle
                        console.log("Sub Title")
                        console.log(subGroupTitle)
                        this.state.line = this.state.line + 2
                      }

                      var space = this.state.line > 28
                      if (this.state.line > 28) {
                        this.state.line = 0
                      }



                      return <View >

                            <View break={true} key={item.test_name + "1"}>

                            </View> 
                            {/* 9-march */}
                            {console.log("check show sub heading Boolen") }
                            {console.log(showSubHeadingBoolean) }
                            {console.log("check show sub heading Boolen") }
                            {console.log(subGroupTitle) }
                            {/* // 13-DEC-2024 new test add same as prev by sir najam  */}

                            {
                          showSubHeadingBoolean==true && subGroupTitle.Conclusion=="Conclusion" && (key.includes("Hydrogen (H2) Breath test for Lactose Intolerance")||key.includes("Hydrogen (H2) Breath test for Fructose Intolerance"))?<>
            <Text style={{ fontSize:4,marginLeft:140,marginTop:2}}>H2(ppm)</Text>

                          {/* <View style={{ width: 400, height: 30, marginTop: 10 }}>
            <Text style={{ textAlign: 'center' }}>Months</Text>
          </View> */}
          {/* <View style={{ width: 30, height: 200, position: 'absolute', left: 20, top: 100, transform: 'rotate(-90deg)' }}>
            <Text style={{ textAlign: 'center' }}>H2(ppm)</Text>
          </View> */}
                            <View style={{
                      width: "100%",
                      paddingTop: 2,
                      marginLeft:140

                    }}>
                    <Image style={{ width: 280,
        height: 140}}  src={this.state.pic}></Image>
                    </View>
            <Text style={{ fontSize:4,marginLeft:430,marginTop:-8}}>Time(min)</Text>

                    </>:<></>}
                        {
                          showSubHeadingBoolean ?
                          <View>
                            <View style={{
                              flexDirection: 'row',
                              justifyContent:'left'
                            }} >
                              <Text style={styles.subReportTitle}>

                                {item.subGroupTitle}

                              </Text>
                              </View>
                              {/* {
                                key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"
                                ?
                                null
                                :
                                <InvoiceTableHeader data={this.state.data.list[key][0]} />

                              } */}
                             
                         
                           </View>
                            :
                            <Text></Text>
                        }
                        
                        <View style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
                         // 24-july-2023
        // marginTop: 0.5,
 // 27-july-2023
 borderColor:"#c5c5c5",
 borderStyle:"solid",

        marginTop: -1,
                          borderWidth: 1
                        }} key={item.test_name}>

<Text style={{

width: key=="Hormonal Profile for Female"  ? '15%' : '20%',
textAlign: 'left',
paddingTop: 2,
paddingLeft: 8,
fontFamily: this.state.data.list[key].length==1 || (key=="Cobas e411" || key=="Cobas C311") ? "Helvetica-Bold" : "Helvetica",
height: '100%',
textAlign: 'left',
fontSize: 7
}}>{item.test_name}</Text>
<Text style={{

width:  key=="Hormonal Profile for Female" ? '20%' : '20%',
// textAlign:"center",
// 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(1/3)
textAlign:item.result.includes("Calcium oxalate monohydrate")?"left":"center",
// borderLeftColor: 'black',
// borderLeftWidth: 0.5,
borderLeftColor: '#c5c5c5',
borderLeftWidth: 1,
paddingTop: 2,
fontWeight: '',
fontSize: 8,
height: '100%',
 // 20-june-2023 7 tha ab 8 kiya
//  paddingLeft:8,
// 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(2/3)
paddingLeft:item.result.includes("Calcium oxalate monohydrate")?0: 8,
fontFamily: "Helvetica-Bold",
 // 20-june-2023 add padding
//  padding:5,
// 4-nov-2023 for calcium oxalate conclusion 0202044 sir asim mpl-it group(3/3)
//9-march-2024
          // 13-DEC-2024 new test add same as prev by sir najam 

padding:key=="Hydrogen (H2) Breath test for Lactose Intolerance"||key=="Hydrogen (H2) Breath test for Fructose Intolerance"?0:item.result.includes("Calcium oxalate monohydrate")?4:5,
// paddingTop:5

}}>{item.result}</Text>
<Text style={{

width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '25%' :  key=="Hormonal Profile for Female" ? '25%' : '20%',
height: '100%',
textAlign: 'left',
// borderLeftColor: 'black',
// borderLeftWidth: 0.5,
borderLeftColor: '#c5c5c5',
borderLeftWidth: 1,
paddingTop: 2,
fontFamily: "Helvetica",
fontSize: 7,
paddingLeft: 8,

}}>
  {console.log("ref range show in 345",item.ref_range)}
  {/* this changes for report 3501378 ref range me - ni aa rhi sir najam shared 31-dec phly srf ye tha {item.ref_range=="" ? "-" : item.ref_range} */}
  {/* 24-jan-2025 */}
 {/* {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range=="" ? "-" : item.ref_range} */}

 {item.ref_range.includes("125")&&item.ref_range.includes("345")?"125-345":item.ref_range.includes("135")&&item.ref_range.includes("392")?"135 - 392":item.ref_range.includes("2.0")&&item.ref_range.includes("3.6")?"2.0 - 3.6":
// 8302796 me koi aur parameter thy us ki jga 12-45 aa rhy thy thats why comment kr dya 12-feb-2025
//  item.ref_range.includes("12")&&item.ref_range.includes("45")?"12 - 45":
 item.ref_range=="" ? "-" : item.ref_range}

  {/* {item.ref_range=="" ? "-" : item.ref_range} */}
</Text>
                          <Text style={{
                            height: '100%',
                            width: '10%',
                            textAlign: 'left',
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            fontFamily: "Helvetica",
                            fontSize: 7,
                            // this condition apply for 1901338 sir asim 24-dec unit long hai line k sth touch ho rha phly 8 tha
                            // paddingLeft:item.unit=="ml/min/1.73m2"?3: 8,
                            paddingLeft:8,
                            
                            
                            flexGrow:1
                          



                          }}>
                            {/*  this condition apply for 1901338 sir asim 24-dec 2 power me nazar aae */}
                            {/* {item.unit==""? "-" :item.unit=="ml/min/1.73m2"?item.unit.slice(0,-1)+"^2":  item.unit} */}

                            {item.unit==""? "-" : item.unit}
                           



                            </Text>

                          <Text style={{

                            width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                            textAlign: 'left',
                            // borderLeftColor: 'black',
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            height: '100%',
                            fontFamily: "Helvetica",
                            fontSize: 6,
                            paddingLeft: 5,


                          }}>{item.prev1=="" ? "-" : item.prev1}</Text>
                          <Text style={{

                            width: key=="Glucose tolerance test with 75 gm Glucose in Pregnancy"  ? '10%' : '15%',
                            height: '100%',
                            textAlign: 'left',
                            // borderLeftWidth: 0.5,
                             // 27-july-2023
                            // borderLeftWidth: 0.5,
                            borderLeftColor: '#c5c5c5',
                            borderLeftWidth: 1,
                            paddingTop: 2,
                            fontSize: 6,
                            fontFamily: "Helvetica",
                            textOverflow: 'auto',
                            paddingLeft: 8

                          }}>{item.prev2=="" ? "-": item.prev2}</Text>

                        </View>

                      
                      </View>
                      
                    })
               
                  )
                  }
                  {
                    this.state.line > 28 ? this.state.line = 0 : null
                  }
                  <View style={{
                    width: "100%",
                    paddingTop: 2
                  }}>
                   
                  <View break={true} key={key + "2132187239"}><Text break={true} ></Text></View>

                  
                  <View>
                    {
                      key.includes("Complete") ?
                      <View style={{
                        paddingTop:10,
                        paddingLeft:10,
                        marginTop:5,
                        paddingBottom:10,
                        marginBottom:1,
                        backgroundColor:"#eeeeee",
                        borderWidth:0.5,
                         // 20-june-2023
                         color:"black"
                      }}>
                      <Text style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 8
                      }}>{"Pathologist Remarks"}</Text>
                      <View style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: "100%",
                          fontSize: 7,
                          paddingTop: 5
                          
    
                        }}
                        >
                          {
                            this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                              this.state.line = this.state.line + 1
                              var space = this.state.line > 28
                              if (data.length > 200) {
                                this.state.line = this.state.line + 1
                              }
    
                              if (data == "") {
                                return <View><Text>{""}</Text></View>
                              }
    
    
                              if (space) {
    
                                return <View style={{
                                  marginTop: 1,
                                  width: "100%",
                                }}>
                                  <View></View>
                                  <Text style={{
                                    textAlign: "left"
                                  }}>{data}</Text>
                                </View>
    
                              }
    
                              return <View style={{
                                marginTop: 1,
                                width: "100%",
                              }}>
                                <View></View>
                                <Text style={{
                                  textAlign: "left"
                                }}>{data}</Text>
                              </View>
                            })
                          }
             
                        </View>
                   
                     </View>
                     :
                   <View style={{
                    paddingTop:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    paddingLeft:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    paddingRight:this.state.data.comments[key].trim().length==0 ? 0 :10,
                     // 20-june-2023
                    // fontFamily:"Roboto",
                    color:"black",
                    minWidth:"100%",
                    marginTop:this.state.data.comments[key].trim().length==0 ? 0 : 5,
                    paddingBottom:this.state.data.comments[key].trim().length==0 ? 0 :10,
                    marginBottom:this.state.data.comments[key].trim().length==0 ? 0 :1,
                    backgroundColor:this.state.data.comments[key].trim().length==0 ? "white" :"#eeeeee",
                    borderWidth:this.state.data.comments[key].trim().length==0 ? 0 :0.5

                    }}
                    >
                      {
                         this.state.data.comments[key].replaceAll("\n\n", "\n").split('\n').map((data) => {
                          this.state.line = this.state.line + 1
                          var space = this.state.line > 28
                          if (data.length > 200) {
                            this.state.line = this.state.line + 1
                          }

                          if (data == "") {
                            return <View><Text>{""}</Text></View>
                          }


                          if (space) {

                            return <View style={{
                              marginTop: 1,
                              width: "100%",
                            }}>
                              <View></View>
                              <Text style={{
                                textAlign: "left"
                              }}>{data}</Text>
                            </View>

                          }

                          return <View style={{
                            marginTop: 1,
                            width: "100%"
                          }}>
                            <View></View>
                            <Text style={{
                              textAlign: "left"
                            }}>{data}</Text>
                          </View>
                        })
                     
                      }
                 
                   
                 
                    </View> 
                    }
                    
                    {/* <Text style={{ fontSize: 7 }}>{this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]}</Text> */}
                {/* this.state.data.list[key][0].test_name this is used for Troponin k sath koi comment na aae ye es machine pe ni hota kch dino k lye remove krna hai ku k ye cobas e411 backend se aa rha hai */}
                {this.state.data.list[key][0].test_name=="Troponin T"?<></>:
                    <Text style={{ fontSize: 7 }}>{this.state.DefaultComments[this.state.data.Machine[key]]==undefined ? this.state.data.MachineComments[key] : this.state.DefaultComments[this.state.data.Machine[key]]}</Text>
                  }
                  </View> 
                    

                  </View>
                </View>
                           


              </View>
               {/* 11-oct-2023 added signature on 3rd page of others reports before URE test 8201989 */}
               {Object.keys(this.state.data.list).length-2==index && Object.keys(this.state.data.list).includes("Urine Routine Examination") ? 
              <View style={{ alignItems: 'flex-end',marginTop:-5  }}>
                                    <Text style={{ fontSize: 6 }}>User ID : {this.state.data.DoctorSignature} Printed on Date   {this.state.data.TodayDate} at {this.state.data.TodayTime}   Inv.Date: {this.state.data.InvoiceDate[0]}  Inv.Time {this.state.data.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                                  
                                    {
                                        // this.state.data.DoctorSignature.includes("Waqar")||this.state.data.DoctorSignature.includes("Najam1") ?
                                        //     <View style={{alignItems: 'flex-end'}}>
                                        //         <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                        //         <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                        //         <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                        //     </View>
                                        //     :
                                        //     (
                                        //       this.state.data.DoctorSignature.includes("Amna") ||this.state.data.DoctorSignature.includes("Najam2") ?
                                            
                                        //     <View style={{alignItems: 'flex-end'}}>
                                        //     <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                                        //     <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>FCPS Microbiology</Text>
                                        //       <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>Consultant Microbiologist</Text>
                                        //       </View>
                                        //       :
                                        //       <></>
                                    // )
                                        this.state.data.DoctorSignature.includes("Najam1") ?
                                        <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>MBBS, Dip. Path. (AFPGMI), FCPS Hematology</Text>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                        </View>
                                        :
                                        
                                          this.state.data.DoctorSignature.includes("Najam2") ?
                                        
                                        <View style={{alignItems: 'flex-end'}}>
                                        <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                                        <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>FCPS Microbiology</Text>
                                          <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>MBBS, DipRCPath & FCPS Microbiology</Text>
                                          </View>
                                          :
                                          this.state.data.DoctorDegree!=""?
                                          <View style={{alignItems: 'flex-end'}}>
                                                         <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{this.state.data.DoctorSignature}</Text>

{this.state.data.DoctorDegree.map((item,index)=>{
return <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{item}</Text> 

})}
                                            </View>
                                            :<></>
                                          
                                            

                                    }
                                </View>
                                :<></>}
             </View>
        }

      </View>


    }))}
       {/* 24-july-2023 this total view */}
       {/*  marginRight: -20 remove this one */}
     {/* <View style={{ alignItems: 'flex-end',  }}> */}
       {/* // 10-aug-2023  oper 219 line  -10 ki -12 thora oper kiya hai 1301825 ye signature 2nd page chala gya tha aur niche signature ko marginTop -5 kiya hai */}

       <View style={{ alignItems: 'flex-end',marginTop:-5  }}>
                                    <Text style={{ fontSize: 6 }}>User ID : {this.state.data.DoctorSignature} Printed on Date   {this.state.data.TodayDate} at {this.state.data.TodayTime}   Inv.Date: {this.state.data.InvoiceDate[0]}  Inv.Time {this.state.data.InvoiceTime[0]}</Text>
                                    <Text style={{ color: 'red', bottom: 0, right: 0, fontSize: 7, fontFamily: 'Helvetica-Bold' }}>This is a digitally verified Report and does not require any Signature</Text>
                                  
                                    {
                                        this.state.data.DoctorSignature.includes("Waqar")||this.state.data.DoctorSignature.includes("Najam1") ?
                                            <View style={{alignItems: 'flex-end'}}>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Col. Retd. Professor Dr. Waqar Ali"}</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>FCPS Hematology</Text>
                                                <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>Consultant Hematologist</Text>

                                            </View>
                                            :
                                            (
                                              this.state.data.DoctorSignature.includes("Amna") ||this.state.data.DoctorSignature.includes("Najam2") ?
                                            
                                            <View style={{alignItems: 'flex-end'}}>
                                            <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{"Dr. Amna Afzal"}</Text>
                                            <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>FCPS Microbiology</Text>
                                              <Text style={{bottom:0, right:0,fontSize:6, fontFamily:'Helvetica'}}>Consultant Microbiologist</Text>
                                              </View>
                                              :  this.state.data.DoctorDegree!=""?
                                              <View style={{alignItems: 'flex-end'}}>
                                                             <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{this.state.data.DoctorSignature}</Text>

{this.state.data.DoctorDegree.map((item,index)=>{
return <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{item}</Text> 

})}
                                                </View>:
                                              <></>
                                              // 10-aug-2023 ye li oper 219 line  -10 ki -12 thora oper kiya hai 1301825
                                        //     <View style={{alignItems: 'flex-end'}}>
                                        //     <Text style={{ bottom: 0, right: 0, fontSize: 6.5, fontFamily: 'Helvetica-Bold' }}>{" "}</Text>
                                        //     <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>
                                        //     <Text style={{ bottom: 0, right: 0, fontSize: 6, fontFamily: 'Helvetica' }}>{" "}</Text>

                                        // </View>
                                            )

                                    }
                                </View>
    </View>
    
  }
}



export default InvoiceItemsTable