import React, { useEffect, useRef ,  Fragment,useState} from "react";

import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

import Modal from "@material-ui/core/Modal";

import NotificationAlert from "react-notification-alert";


import MaterialTable from "material-table";

import PrintIcon from '@material-ui/icons/Print';

import FormControl from "@material-ui/core/FormControl";
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import { addYears, set, max } from "date-fns";

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import Report from './Report/Report'
import { render } from "react-dom";
import PatientImgEx from "../images/avatarNew2.jpg"
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// 9-march
import { Line } from 'react-chartjs-2';





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});


export default function Printing() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([])
  const [invoiceID, setInvoiceID] = React.useState("")
  const [Invalid, setInvalid] = React.useState(false)
  // Call Patient
  const [id,setId]=React.useState("")
  const [name,setName]=React.useState("")
  const [mobile,setMobile]=React.useState("")
  const [search_table, setSearch_table] = React.useState([]);
  const [open,setOpen]=React.useState(false)
  const [invoices,setInvoices]=React.useState([])
 
  // Invoice Record
  const [openInvoice,setOpenInvoice]=React.useState(false)
  // To Print the Reports
  const [toPrint, setToprint] = React.useState({})

  // Report Data Insertion
  const [reportData,setReportdata]=React.useState([])

  // Report Visibilty
  const [reportVisibility,setReportVisibility]=React.useState(false)

  // Print Indicator
  const [printIndicator, setPrintiDicator] = React.useState(false)

  // Select Controller
  const [SelectionControl,setSelectionControl]=React.useState(true)
  // 9-march
  const chartRef = useRef(null);
  const [chartImage, setChartImage] = React.useState(null);
  const [chartdata,setchartdata]=React.useState([])
  const [reportVisibilitynew,setReportVisibilitynew]=React.useState(false)
  const [countdown,setcountdown]=React.useState(0)
  // 20-march-2024 max value dynamic k lye graph ki
  const [maxvalueOfGraph,setmaxValueOfGraph]=React.useState(25)

 
  //yhn tk
 // 21-oct-2021 specail Reports WES & NIPT
 const [reportVisibilitySpecialTest,setreportVisibilitySpecialTest]=React.useState(false)

  const handleClose = () => {
    setOpen(false);
  };
  const inputRef = useRef(null);
  useEffect(() => {
    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"WebPortal_Reporting"
  }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      console.log(res)
      if(res[0].Option_Status=="Authorized"){
        
      }else{
        localStorage.clear()
        window.location.href = "https://printing.mpl-labs.pk/"
      }
    })
  }, [])
  // 9-march
  // useEffect(() => {
  //   if (chartRef.current) {
  //     const canvas = document.createElement('canvas');
  //     canvas.width = 2400; // Adjust based on your chart width
  //     canvas.height = 1200; // Adjust based on your chart height
  //     const ctx = canvas.getContext('2d');

  //     // Render chart to the canvas
  //     const chartCanvas = chartRef.current.chartInstance.canvas;
  //     ctx.drawImage(chartCanvas, 0, 0, canvas.width, canvas.height);

  //     // Convert canvas to image URL
  //     const image = canvas.toDataURL('image/png');
  //     setChartImage(image);
  //   }
  // });
  // Sample data for the chart
  // const fakedata=[10,50,30,80,50,60]
  const data = {
    labels: ['0', '30', '60', '90', '120', '150', '180'],
    datasets: [
      {
        label: 'H2(ppm)',
        data: [9, 25, 31, 59, 52, 41, 61],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
        borderWidth: 5, 
        // 
        pointBackgroundColor: 'rgba(75,192,192,1)', // Color of the points
        pointStyle: 'circle', // Shape of the points
        pointRadius: 6,
      }
    ]
  };

  // Options for the chart
  const optionsn = {
    scales: {
      yAxes: [
        {
          ticks: {
            // 20-march-2024 max value dynamic k lye graph ki
            // beginAtZero: false,
            // suggestedMax: 25,
            beginAtZero: true,
            suggestedMax: maxvalueOfGraph,
          }
        }
      ]
    }
    
    

    // scales: {
    //   x: {
    //     title: {
    //       display: true,
    //       text: 'Time(min)' // X axis heading
    //     },
    //     ticks: {
    //       font: {
    //         weight: 'bold' // Bolden x-axis labels
    //       }
    //     }
    //   },
    //   y: {
    //     // beginAtZero: true, 
    //     min: 1,// Start y-axis from a value other than 0
    //     title: {
    //       display: true,
    //       text: 'H2(ppm)' // Y axis heading
    //     },
    //     ticks: {
    //       font: {
    //         weight: 'bold' // Bolden x-axis labels
    //       }
    //     }
    //   }
    // }
  };

  // Convert the chart to base64
  const chartData = {
    labels: data.labels,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      data: chartdata
    }))
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  function sortKeys(obj_1) {
    var key = Object.keys(obj_1)
    .sort(function order(key1, key2) {
     
        if ((obj_1[key1][0].TestAlignOrder) < (obj_1[key2][0].TestAlignOrder)) return -1;
        else if ((obj_1[key1][0].TestAlignOrder) > (obj_1[key2][0].TestAlignOrder)) return +1;
        else return 0;
    }); 
    console.log("Keys"+key.toString())
      
    // Taking the object in 'temp' object
    // and deleting the original object.
    var temp = {};
      
    for (var i = 0; i < key.length; i++) {
        temp[key[i]] = obj_1[key[i]];
        delete obj_1[key[i]];
    } 

    // Copying the object from 'temp' to 
    // 'original object'.
    for (var i = 0; i < key.length; i++) {
        obj_1[key[i]] = temp[key[i]];
    } 
    return obj_1
   
}

const handleOpen = () => {
  setOpen(true);
};
const search_patient = () => {
  console.log(name)
  fetch(
    "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetsPatientsList?vName=" +
    name +
    "&vMobile=" +
    mobile +
    "&vID=" +
    id
  )
    .then((res) => res.json())
    .then((response) => {
      var data = [];
      response.map((item) => {
        console.log(item)
        var obj = {
          Patient_ID: item.Patient_ID,
          Patient_Name: item.Patient_Name,
          MobileNo: item.MobileNo,
          ToAddress: item.ToAddress,
          eMail: item.eMail,
          cnic: item.CNIC,

          select: (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
            setId(item.Patient_ID)
            setName(item.Patient_Name)
            setMobile(item.MobileNo)
            handleClose()
              }}
            >
              Select
            </Button>
          ),
        };

        data.push(obj);
      });
      setSearch_table(data);
      handleOpen(true);
    });
};
function TestLineSort(obj1) {
  console.log("Data = ")
  console.log(obj1)

  function compare( a, b ) {
    if ( a.alignNumber < b.alignNumber ){
      return -1;
    }
    if ( a.alignNumber > b.alignNumber ){
      return 1;
    }
    return 0;
  }
  Object.keys(obj1).map((value)=>{
    obj1[value].sort( compare )
  })

}

  function msToTime(seconds) {
 
    var minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
    var hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;
  
    return addZero(hours) + ':' + addZero(minutes);
  }
  const passOrderToPrint = () => {
    console.log("pass order to print "+JSON.stringify(toPrint))
    // 9-march-2024
    setcountdown(countdown+1)
    if(toPrint.AllowPrint.includes(false)){
      alert("UnPaid, UnPaid Report can't Print")
      return
    }
    if(toPrint.departNames.includes("MICROBIOLOGY") && toPrint.departNames.length!=1){
      alert("MicroBiology, One Report can be Printed at a Time.")
      return
    }
    //7-aug-2024
// temporaray disable 24-oct
if(toPrint.departNames.includes("ParaGraPH") && toPrint.departNames.length!=1){
  alert("ParaGraPH, One Report can be Printed at a Time.")
  return
}

    if(toPrint.departNames.includes("HISTOPATHOLOGY") ){
      alert("Please Unselect Histopathology Reports and Print from 4d for few days ! ")
      return
    }

   
    // 10-aug-2024 aik new paragrapgh report hai us ko b restrict krna hai
// temporaray disable 24-oct
    
    if(toPrint.natures.includes("ParaGraPH") ){
      alert("Please Unselect paragraphic Report and Print from 4d for few days ! ")
      return
    }
    if(toPrint.departNames.includes("Special Pathology") && toPrint.natures.includes("ParaGraPH") && toPrint.natures.includes("RanGE")){
      alert("Special Pathology, One Report can be Printed at a Time.")
      return
    }
    //9-march
    if(toPrint.auXiD.includes("603994") && toPrint.auXiD.length!=1){
      alert("Graph Report, One Report can be Printed at a Time.")
      return
    }
// 21-oct-2024 specail Reports WES & NIPT
if(toPrint.testCode.includes("WES")  && toPrint.auXiD.length!=1){
  // alert("Special Reports WES, cannot print with other reports !" )
  alert("Special Reports WES, cannot print with other reports Please Unselect and Print from 4d  !" )

  return
}
// temporaray disable 24-oct

if(toPrint.testCode.includes("WES") ){
  // alert("Special Reports WES or NIPT cannot print with other reports !" )

  // setreportVisibilitySpecialTest(true)
      alert("Please Unselect Special Report and Print from 4d for few days ! ")

  return
}
if(toPrint.testCode.includes("NPIT")  && toPrint.auXiD.length!=1){
  // alert("Special Reports NIPT cannot print with other reports !" )
  alert("Special Reports NIPT, cannot print with other reports Please Unselect and Print from 4d  !" )

  return
}
// temporaray disable 24-oct

if(toPrint.testCode.includes("NIPT") ){

  // setreportVisibilitySpecialTest(true)
  alert("Please Unselect Special Report and Print from 4d for few days ! ")

  return
}



    var obj={
      ArrayInvoiceNo:toPrint.inVoiceID,
      ArrayAux_IDs:toPrint.auXiD,
      //for print Image
      ArrayGroupID:toPrint.groupIdPicDisp
    }
    console.log("check obj "+JSON.stringify(obj))
     fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_ReportsPrintingResultsCalls", {
      // fetch("http://202.142.169.174:8090/4DACTION/Web_ReportsPrintingResultsCalls", {
    
      method:"POST",
      body:JSON.stringify(obj)
  }).then(res=>res.json()).then((response)=>{
    console.log("Web_ReportsPrintingResultsCalls"+JSON.stringify(response))
    svgString2Image(response[0].Barcode ,800, 600,'png',(Barcode)=>{
      
     var prevTestName=""
     var list=[]
     var counter=0
     var temp={}
     var comments=[]
     var speicmenDate=[]
     var specimenTime=[]
     var testLines={}
     // TestNature
     var testNature={}
    // Invoice Time
    var InvoiceTime=[]
     // Result
     
     var commentsTest={}
     var Germs={}
     var DoctorSignature={}
       //  15-may-2024 to add doctor degree dynamic
    var DoctorDegree={}

     var rowCounter=0

    // Result Date & Time
    var ResultDate=[]
    var ResultTime=[]
    var month= ["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]
    //Department
     var department=""
     var GermCounter=0
     var HistoFlag=false
     // COVID-19 Flag
     var Covid19Flag=false

     // COVID-19 AntiGen
     var Covid19AntiGen=false

     // Department Fexible
     var FexibleDeptName=""

     // MachineName
     var Machine={}
     var MachineComments={}

     // Microbiology
     var Microbiology={}

     //Hematology
     var Hematology={}

     // Histopathology
     var Histopathology={}

     // Other
     var Other={}

     // COVID-19 RECORD
     var covid_record={}

     // Object Sample Date & Time
     var ObjSampleDate=""
     var ObjSampleTime=""
    //  9-march
      
       var chartDataCollection=[]


     // Object Result Date & Time
     var ObjResultDate=""
     var ObjResultTime="" 

     var TodayDate=new Date().getDate()+"-"+(month[new Date().getMonth()])+"-"+new Date().getFullYear()
     var TodayTime= new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()

     console.log(response[0].ArrMedicineGroupName)
     if(response[0].ArrMedicineGroupName!=""){

     
    JSON.parse(response[0].ArrMedicineGroupName).map((value)=>{
      if( Germs[value]==undefined){
        Germs[value]=[{
          Medicine:JSON.parse(response[0].ArrMedicineName)[GermCounter],
          Result:JSON.parse(response[0].ArrMedicineGerm1)[GermCounter],
          Result2:JSON.parse(response[0].ArrMedicineGerm2)[GermCounter],
          Result3:JSON.parse(response[0].ArrMedicineGerm3)[GermCounter],
          Result4:JSON.parse(response[0].ArrMedicineGerm4)[GermCounter],
          Result5:JSON.parse(response[0].ArrMedicineGerm5)[GermCounter]
         }] 
         GermCounter++
         return
      }
       Germs[value].push(
         {
          Medicine:JSON.parse(response[0].ArrMedicineName)[GermCounter],
          Result:JSON.parse(response[0].ArrMedicineGerm1)[GermCounter],
          Result2:JSON.parse(response[0].ArrMedicineGerm2)[GermCounter],
          Result3:JSON.parse(response[0].ArrMedicineGerm3)[GermCounter],
          Result4:JSON.parse(response[0].ArrMedicineGerm4)[GermCounter],
          Result5:JSON.parse(response[0].ArrMedicineGerm5)[GermCounter]
         }
       )
       GermCounter++
    }
     )
  }
     JSON.parse(response[0].ArrayTestName).map((name)=>{
      console.log("name is ArrayTestName")
      console.log(name)
       if(response[0].ArrayTestName.includes("Covid-19 RNA by PCR")){
         Covid19Flag=true
      }
      if(response[0].ArrayTestName.includes("COVID-19 Rapid Antigen Test (RAT)")){
        Covid19AntiGen=true
     }

      
        if(temp[name]==undefined){
          DoctorSignature=JSON.parse(response[0].ArrayUserSignName)[counter]
             // 15-may-2024 to add doctor degree dynamic 
          // DoctorDegree=(JSON.parse(response[0].DoctorDegree)[counter]).replace(/\r/g, '\n')
          DoctorDegree=(JSON.parse(response[0].DoctorDegree)[counter]).split('\r');
          // Result 
            var result=JSON.parse(response[0].ArrayTestResult)[counter].split("\r")

          // Result Date & Time
          ResultDate.push(new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear())
          ResultTime.push(JSON.parse(response[0].ArrReportingTime)[counter])
          console.log("Month")
          console.log(new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth())
          // Test Nature
          testNature[name]=JSON.parse(response[0].ArrayTestNature)[counter]
          if(JSON.parse(response[0].ArrayTestNature)[counter]=="ParaGraPH"){
            HistoFlag=true
          }
          
         
          // Ref Range
          var refLines=JSON.parse(response[0].ArrayRefRange)[counter].split("\r")
         

          var commentsLines=JSON.parse(response[0].ArrayCommentsTest)[counter].split("\r")
          console.log(commentsLines)

          testLines[name]={
            refLines:refLines.length,
            commentsLines:commentsLines.length
          }

          // Machine
          var MachineName=JSON.parse(response[0].ArrayCommentsMachine)[counter]
          if(MachineName=="Cobas C111"){
            MachineName="Cobas C311"
          }
          Machine[name]=MachineName
          console.log("Machine Comments")
          var eachMachineComment=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").split("Please note:-")[1]
          console.log("%%%%^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
          console.log(eachMachineComment)
          if(eachMachineComment==undefined){
            eachMachineComment=""
          }
          MachineComments[name]=eachMachineComment==""? "": "Please note:- "+eachMachineComment
          console.log(MachineComments[name])

          //Comments
          if(JSON.parse(response[0].ArrayCommentsMachine)[counter]!="Manual"){
            commentsTest[name]=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ").split("Please note:-")[0]

            comments.push(JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ").split("Please note:-")[0])
            
          }else{
            
            commentsTest[name]=JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," ")

            comments.push(JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "))
          }
          
          ObjSampleDate=new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear()
          ObjSampleTime=JSON.parse(response[0].ArraySampleTime)[counter]
          ObjResultDate=new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear()
          ObjResultTime=JSON.parse(response[0].ArrReportingTime)[counter]

          //Specimen
          speicmenDate.push(new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear())
          
          specimenTime.push(JSON.parse(response[0].ArraySampleTime)[counter])
          

          // Invoice Time 
          InvoiceTime.push(JSON.parse(response[0].ArrayInvoiceTime)[counter])
          //Department ID
          department=toPrint.department[rowCounter]
          // Department Name
          FexibleDeptName=toPrint.departNames[rowCounter]
          if(testNature[name]=="ParaGraPH"){
            FexibleDeptName="HISTOPATHOLOGY"
          }
          

          temp[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
              speicmenDate:JSON.parse(response[0].ArraySampleDate)[counter],
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:JSON.parse(response[0].ArrReportingDate)[counter],
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
                // newValueGroupTitle:"My New Group Add",
              }
          ]
          rowCounter++
          //15-june-2023 JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY" agr nature me micro aae ga to micro ki array fill ho aur micro ka format use ho otherwise wo Other me jae refrange wala format bn jae
          //
          if(FexibleDeptName=="MICROBIOLOGY" &&JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY"){
            Microbiology[name]=[
              {
                title:name,
                rowHeight:refLines.length,
                resultLines:result.length,
                test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
                result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                unit:JSON.parse(response[0].ArrayUnit)[counter],
                prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
                space:true,
                alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
                subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
                TestAlignOrder:department,
                speicmenDate:JSON.parse(response[0].ArraySampleDate)[counter],
                specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
                reportDate:JSON.parse(response[0].ArrReportingDate)[counter],
                reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                  //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // newValueGroupTitle:"My New Group Add",
            }
            ]
          }else if(FexibleDeptName=="HISTOPATHOLOGY"){
            Histopathology[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
                // newValueGroupTitle:"My New Group Add",
              }
          ]
          }else if(FexibleDeptName=="HEMATOLOGY"){
            Hematology[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:name.includes("Complete")? 1 : department,
              speicmenDate:new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear(),
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear(),
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              //  newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              //  newValueGroupTitle:"My New Group Add",
            
              }
          ]
          }else{
            console.log("73333333333 name check ")
            console.log(name)

            Other[name]=[
            {
              title:name,
              rowHeight:refLines.length,
              resultLines:result.length,
              test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
              result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              unit:JSON.parse(response[0].ArrayUnit)[counter],
              prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
              space:true,
              alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
              subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
              TestAlignOrder:department,
              MachineName:MachineName,
              speicmenDate:new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArraySampleDate)[counter]).getFullYear(),
              specimenTime:JSON.parse(response[0].ArraySampleTime)[counter],
              reportDate:new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getDate()+"-"+(month[new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getMonth()])+"-"+new Date(JSON.parse(response[0].ArrReportingDate)[counter]).getFullYear(),
              reportTime:JSON.parse(response[0].ArrReportingTime)[counter],
                //for groupId and title
                // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
                // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
                // newValueGroupTitle:"My New Group Add",
              }
          ]
          console.log("Start")
          console.log(Other)
    

          }
          
        }else{
          // Result 
          var result=JSON.parse(response[0].ArrayTestResult)[counter].split("\r")

          // Ref Range
         
          var refLines=JSON.parse(response[0].ArrayRefRange)[counter].split("\r")
          testLines[name].refLines=testLines[name].refLines+refLines.length

          // Comments Lines
          var commentsLines=JSON.parse(response[0].ArrayCommentsTest)[counter].split("Please note:-")[0].split("\r")
          console.log(commentsLines)
          testLines[name].commentsLines=testLines[name].commentsLines+commentsLines.length

          // Test Nature
          testNature[name]=JSON.parse(response[0].ArrayTestNature)[counter]
          if(testNature[name]=="ParaGraPH"){
            FexibleDeptName="HISTOPATHOLOGY"
          }

          temp[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // newValueGroupTitle:"My New Group Add",
          })
          if(FexibleDeptName=="MICROBIOLOGY"&&JSON.parse(response[0].ArrayTestNature)[counter]=="MicrobioLoGY"){
            Microbiology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // newValueGroupTitle:"My New Group Add",
          })
          }else if(FexibleDeptName=="HISTOPATHOLOGY"){
            Histopathology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // newValueGroupTitle:"My New Group Add",
          })
          }else if(FexibleDeptName=="HEMATOLOGY"){
            Hematology[name].push({
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:name.includes("Complete")? 1 : department,
            speicmenDate:  ObjSampleDate,
            speicmenTime: ObjSampleTime,
            reportDate: ObjResultDate,
            reportTime: ObjResultTime,
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
              // newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
              // newValueGroupTitle:"My New Group Add",
          })
          }else{
            Other[name].push(
              {
            title:name,
            rowHeight:refLines.length,
            resultLines:result,
            test_name:JSON.parse(response[0].ArrayTestLineTitle)[counter],
            result:JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            ref_range:JSON.parse(response[0].ArrayRefRange)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            unit:JSON.parse(response[0].ArrayUnit)[counter],
            prev1:JSON.parse(response[0].ArrayPrevious1)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            comments:JSON.parse(response[0].ArrayCommentsTest)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            prev2:JSON.parse(response[0].ArrayPrevious2)[counter].replaceAll("\r","\n").replaceAll("\t"," "),
            space:true,
            alignNumber:JSON.parse(response[0].ArraySortingGroup)[counter],
            subGroupTitle:JSON.parse(response[0].ArrayGroupTitle)[counter],
            TestAlignOrder:department,
            speicmenDate:  ObjSampleDate,
            speicmenTime: ObjSampleTime,
            reportDate: ObjResultDate,
            reportTime: ObjResultTime,
              //for groupId and title
              // newValueGroupId:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupID)[counter]:null,
            //  newValueGroupTitle:response[0].ArrNewValueGroupID ?JSON.parse(response[0].ArrNewValueGroupTitle)[counter]:null,
            //  newValueGroupTitle:"My New Group Add",
           
    
          }
        )
    //  chartDataCollection.push(JSON.parse(response[0].ArrayTestResult)[counter].replaceAll("\r","\n").replaceAll("\t"," "))

          }
        }
          
      counter++;

      })
     console.log(TestLineSort(Hematology))
     console.log(sortKeys(Hematology))
     

      console.log(temp)

      console.log("Microbiology")
      console.log(Microbiology)
      console.log("Histopathology")
      console.log(Histopathology)
      console.log("Hematology")
      console.log(Hematology)
      console.log("Other")
      console.log(Other)
      // console.log("Other:", Other);
//       const key = Object.keys(Other).find(k => k.includes("Architect i1000 SR"));
// if (key) {
//   console.log(`Found key: ${key}`);
//   console.log(Other[key]);
// } else {
//   console.error("Key 'Architect i1000 SR' not found.");
// }
    //   var title = Other.map((key) => {
    //     return key
    // })
    // console.log(title)
//       const key = Object.keys(Other).find(k => k.trim() === 'Architect i1000 SR');
// if (key) {
//   console.log(Other[key]);
// } else {
//   console.error("Key not found.");
// }
      // console.log(Other[0])

      // if (Other["Architect i1000 SR"] && Array.isArray(Other["Architect i1000 SR"])) {
      //   Other["Architect i1000 SR"] = Other["Architect i1000 SR"].sort((a, b) => {
      //     if (a.title === '25-OH Vitamin D Total') return 1;
      //     if (b.title === '25-OH Vitamin D Total') return -1;
      //     return 0;
      //   });
      //   console.log(Other["Architect i1000 SR"]);
      // } else {
      //   console.error("Other['Architect i1000 SR'] is not a valid array.");
      // }
      // if (Array.isArray(Other["Architect i1000 SR"])) {
      //   Other["Architect i1000 SR"] = Other["Architect i1000 SR"].sort((a, b) => {
      //     if (a.title === '25-OH Vitamin D Total') return 1;
      //     if (b.title === '25-OH Vitamin D Total') return -1;
      //     return 0;
      //   });
      // } else {
      //   console.error("Other['Architect i1000 SR'] is not an array or is undefined.");
      // }
      // const check2 = Other["Architect i1000 SR"]?.map((str,index) => {
      //   // parseInt(str)}
      //   console.log("check this one to find index for remove")
      //   console.log(index)

      //   console.log(str)}
      // )
      
      console.log(Other);
      // 9-march
      const check = Other["Hydrogen (H2) Breath test for Lactose Intolerance"]?.map((str,index) => {
        // parseInt(str)}
        console.log("check this one to find index for remove")
        console.log(index)

        console.log(str)
     // 24-june-2024 3402443 issue ye aaya tha jo prev reports k index thy wo oper nichy ho gae thy jis me last wala text b b graph me aa rha tha jis ki wja se grapgh ki line ni bni to mny new report 3402443 k jo sequence chal rha tha us hisab se index set kr dye to line draw ho gae 
      // 
        // if(index==1||index==2||index==4){
        //   return
        // }
      //  this for 4302246 first report of HBTLI

        // 24-JULY-2024 es ko dobara uncomment kr dya ye phli report wala he tha 4302246 wali 4502534 ye aae 24-july ko srf oper wali ka pattern change hua 
        // if(index==0 ||index==2||index==3){
        //   return
        // }
        if(Other["Hydrogen (H2) Breath test for Lactose Intolerance"][4].result=="Lactose Malabsorption (Lactose Intolerance)")
          {
             if(index==1||index==2||index==4){
          return
        }
          }
          else{
            // 3 value remove krni hoti hn 1se and last two wo dekhny hoty hn us ka index kya hai jo un ka index hai us ko yahan likhna hota
             if(index==0 ||index==2||index==3){
          return
        }
          }
          // yhan tak 21-sep-2024
        chartDataCollection.push(str.result)

          }    );
          // 13-DEC-2024 new test add same as prev by sir najam  5702826
          const checkNEW = Other["Hydrogen (H2) Breath test for Fructose Intolerance"]?.map((str,index) => {
            // parseInt(str)}
            console.log("check this one to find index for remove")
            console.log(index)
    
            console.log(str)
         // 24-june-2024 3402443 issue ye aaya tha jo prev reports k index thy wo oper nichy ho gae thy jis me last wala text b b graph me aa rha tha jis ki wja se grapgh ki line ni bni to mny new report 3402443 k jo sequence chal rha tha us hisab se index set kr dye to line draw ho gae 
          // 
            // if(index==1||index==2||index==4){
            //   return
            // }
          //  this for 4302246 first report of HBTLI
    
            // 24-JULY-2024 es ko dobara uncomment kr dya ye phli report wala he tha 4302246 wali 4502534 ye aae 24-july ko srf oper wali ka pattern change hua 
            // if(index==0 ||index==2||index==3){
            //   return
            // }
            if(Other["Hydrogen (H2) Breath test for Fructose Intolerance"][4].result=="Lactose Malabsorption (Lactose Intolerance)")
              {
                 if(index==1||index==2||index==4){
                  console.log("up")
              return
            }
              }
              else{
                //  if(index==0 ||index==2||index==3)
                // index 3 pe value 1 first wali wrong kr rha tha 2 jga 25,25 kr rha tha dekhny k lye 5702826 pe dekh skty es senarion pe abi 0,2,4 wali value remove ki hain tab grapgh accurate bna
            // 3 value remove krni hoti hn 1se and last two wo dekhny hoty hn us ka index kya hai jo un ka index hai us ko yahan likhna hota
              
            //     if(index==0 ||index==2||index==4)

            //       {
                    
            //       console.log("down")
            //       console.log(str)

            //   return
            // }
                 if(index==0 ||index==2||index==4)

                  {
                    
                  console.log("down")
                  console.log(str)

              return
            }
              }
              // yhan tak 21-sep-2024
            chartDataCollection.push(str.result)
    
              }    );
      // 9-march
      console.log("chartData")
      console.log(chartDataCollection)
      // const newArray = chartDataCollection.slice(1,-1);
      
      const arrayIntegers = chartDataCollection.map(str => parseInt(str));
      // ye phly 0 dala tha yhan aur x-axis pe 0 k bad 1 dal dya tha aur graph design ho gya tha
      // 20-march-2024 max value dynamic k lye graph ki
        // 9-march
        console.log("arrayIntegers")
        console.log(arrayIntegers)
      const maxValue = Math.max(...arrayIntegers);
      console.log("maxValue")
      console.log(maxValue)
      setmaxValueOfGraph(maxValue+19.0)
      //yhan tak  20-march-2024 max value dynamic k lye graph ki
      
      // arrayIntegers.splice(0, 0, 0); 
      if(chartDataCollection.length>1){
        setchartdata(arrayIntegers)
        setReportVisibilitynew(true)
        const canvas = document.createElement('canvas');
        canvas.width = 2400; // Adjust based on your chart width
        canvas.height = 1200; // Adjust based on your chart height
        const ctx = canvas.getContext('2d');
  
        // Render chart to the canvas
        const chartCanvas = chartRef.current.chartInstance.canvas;
        ctx.drawImage(chartCanvas, 0, 0, canvas.width, canvas.height);
  
        // Convert canvas to image URL
        const image = canvas.toDataURL('image/png');
        setChartImage(image);
        console.log(image)
     
      console.log("chartDataNew")
      console.log(arrayIntegers)
      }
     
    
       
      var Cobas311_411={}
      Object.keys(Other).map((key)=>{
        console.log("check key 741"+key)
        console.log("check key 742"+Other[key][0].MachineName)

        if(Other[key].length==1){
      //  feb 2024 aik he machine k test aik sth aaen jaisy cobas 311 and e 411 k aaty hn ye es k lye  Architect i1000 SR
           
          //Other[key][0].MachineName=="Architect i1000 SR"
          if((Other[key][0].MachineName=="Architect i1000 SR" ||Other[key][0].MachineName=="Cobas C311" || Other[key][0].MachineName=="Cobas e411") && key.includes(Other[key][0].test_name) ){
            Other[key][0].TestAlignOrder=8.5
          }
          if(Other[key][0].MachineName=="Cobas C311" && (Other[key][0].result.includes("**") ||commentsTest[key].trim()=="" || commentsTest[key].trim().toLowerCase()=="sample received from outside." || commentsTest[key].trim().toLowerCase()=="sample received from outside laboratory.") && key.includes(Other[key][0].test_name)){
            if(Other["Cobas C311"]==undefined){
             
           Other[key][0].TestAlignOrder=8.5

           if(Other[key][0].result.includes("**")){
            Other[key][0].TestAlignOrder=8.7 
           }

              Other["Cobas C311"]=[...Other[key]]
            }else{
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
              }

              Other["Cobas C311"].push(...Other[key])
            
            }

            
            Machine["Cobas C311"]="Cobas C311"
            if(commentsTest["Cobas C311"]==undefined){
              
              commentsTest["Cobas C311"]=commentsTest[key]
            
            }else{
            if(commentsTest["Cobas C311"].length<commentsTest[key].length){
              commentsTest["Cobas C311"]=commentsTest[key]
            }
          }
            delete Other[key]
           
          }
          if(Other[key]!= undefined && Other[key][0].MachineName=="Cobas e411" && (Other[key][0].result.includes("**") || commentsTest[key].trim()=="" || commentsTest[key].trim().toLowerCase()=="sample received from outside." || commentsTest[key].trim().toLowerCase()=="sample received from outside laboratory.") && key.includes(Other[key][0].test_name)){
            console.log("####################################################### cobas e411")
            console.log(commentsTest[key])
            if(Other["Cobas e411"]==undefined){
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }
              Other["Cobas e411"]= [...Other[key]]
            console.log("check 793 ")
            console.log(Other["Cobas e411"])


            }else{
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }

              Other["Cobas e411"].push(...Other[key])
            }
           
  
            Machine["Cobas e411"]="Cobas e411"
            if(commentsTest["Cobas e411"]==undefined){
              commentsTest["Cobas e411"]=commentsTest[key]
            }else{
              if(commentsTest["Cobas e411"].length<commentsTest[key].length){
                commentsTest["Cobas e411"]=commentsTest[key]
              }

            }

            delete Other[key]
          }
              //  feb 2024 aik he machine k test aik sth aaen jaisy cobas 311 and e 411 k aaty hn ye es k lye  Architect i1000 SR
          if(Other[key]!= undefined && Other[key][0].MachineName=="Architect i1000 SR"  && key.includes(Other[key][0].test_name)){
            console.log("#######################################################")
            console.log(commentsTest[key])
           
            
            if(Other["Architect i1000 SR"]==undefined){
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }
              Other["Architect i1000 SR"]= [...Other[key]]
            }
            else{
              Other[key][0].TestAlignOrder=8.5
              if(Other[key][0].result.includes("**")){
                Other[key][0].TestAlignOrder=8.7
               }
              Other["Architect i1000 SR"].push(...Other[key])
            }
 

           
  
            Machine["Architect i1000 SR"]="Architect i1000 SR"
            if(commentsTest["Architect i1000 SR"]==undefined){
              console.log("check 833")
              console.log(commentsTest)
              console.log(key)


              console.log(commentsTest[key])

              commentsTest["Architect i1000 SR"]=commentsTest[key]
            }else{
              console.log("check 836")

              if(commentsTest["Architect i1000 SR"].length<commentsTest[key].length){
              console.log("check 839")

                commentsTest["Architect i1000 SR"]=commentsTest[key]
              }

            }

            delete Other[key]
          }

          
        }
      })
       
  


     console.log(TestLineSort(Other))
     console.log(sortKeys(Other))

   var covid_antigen={}
      
     var BLOB={
       barcode:Barcode,
       DoctorSignature:DoctorSignature,
       //  15-may-2024 to add doctor degree dynamic ye abi histo and micro me update ki hai hema and simple report me ni ki 
      DoctorDegree:DoctorDegree,
       testLines:testLines,
       invoice_id:JSON.parse(response[0].ArrayInvoiceNo)[0],
      //  27-july-2023
       patient_id:rows[0].patientID,
       name:response[0].PatientName,
       age_gender:response[0].PatientAge,
       list:Other,
       Cobas311_411:Cobas311_411,
       covid_antigen:covid_antigen,
       Hematology:Hematology,
       Microbiology:Microbiology,
       Histopathology:Histopathology,
       Germs:Germs,
       HistoFlag:HistoFlag,
       MachineComments:MachineComments,
       TodayDate:TodayDate,
       TodayTime:TodayTime,
       InvoiceTime:InvoiceTime,
       InvoiceDate:toPrint.date,
       Machine:Machine,
       covid_record:covid_record,
       ReferenceNo:response[0].ReferenceNo,
       MB_Germ1:response[0].MB_Germ1,
       MB_Germ2:response[0].MB_Germ2,
       MB_Germ3:response[0].MB_Germ3,
       MB_Germ4:response[0].MB_Germ4,
       MB_Germ5:response[0].MB_Germ5,
       testNature:testNature,
       Ref_By:response[0].Ref_By,
       speicmenDate:speicmenDate,
       specimenTime:specimenTime,
       ResultDate:ResultDate,
       ResultTime:ResultTime,
       comments:commentsTest,
       user_id:"Najam",
        //for image print in pdf
        PatientImage:response[0].If_PicturePrintingTrue?"data:image/png;base64,"+response[0].PictureImageBase64:null,
        // PatientImage:PatientImgEx,
        //  GroupTitleNew:response[0].varGroupTitle!=undefined?response[0].varGroupTitle:null,
        // 19-oct-2024 090824089 this patient group + 1 other test
        GroupTitleNew:rows[0].patientID=="090824089"?"Pre Dialysis Profile":response[0].varGroupTitle!=undefined?response[0].varGroupTitle:null,

        // GroupTitleNew:"My new GroupT"
          // for new MR panel 3-JAN-2023 && 4-jan-2023 this cond add ==true && response[0].cnic!=""
      cnic:response[0].if_panelMR_cnic_father==true && response[0].cnic!=""? response[0].cnic:"null",
      // cnic:"37405-1287363-9",
      father_husband:response[0].if_panelMR_cnic_father==true && response[0].father_husband!=""?response[0].father_husband:"null"
      // father_husband:"Ashraf Mehmood"
    
      }
      console.log("Printing 1114")
   console.log(BLOB)


setReportVisibility(true)



setTimeout(()=>{
  if(Covid19Flag && !Covid19AntiGen){
    
  fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAux).then(res => res.json()).then((covid) => {

      svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
        svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
          svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
            var test_name = covid[0].test_name
            if (covid[0].test_name == "") {
              test_name = "Covid-19 RNA by PCR"
            }
            var cnic = covid[0].cnic + ""
            var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

            covid_record = {
              barcode:barcode,
               StringDOB:covid[0].StringDOB,
              invoice_id:covid[0].invoice_id,
              QR_Code_Online:QR_Code_Online,
              Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
              Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,

              equipment_used_1:covid[0].equipment_used_1,
              equipment_used_2:covid[0].equipment_used_2,
              regent_kits_1:covid[0].regent_kits_1,
              regent_kits_2:covid[0].regent_kits_2,
              batch_number_1:covid[0].batch_number_1,
              batch_number_2:covid[0].batch_number_2,

              QR_Code_Offline:QR_Code_Offline,
              Airline:covid[0].air_line,
              country:covid[0].country,
              dob:covid[0].dob,
              flight_no:covid[0].flight_no,
              flight_date:covid[0].flight_date,
              final_time:covid[0].final_time,
              ticket_no:covid[0].ticket_no,
              e_mail:covid[0].e_mail,
              contact:covid[0].PatientMobile,
              name:covid[0].name,
              passport:covid[0].passport,
              ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
              title:test_name,
              age_gender:covid[0].age_gender,
              specimen_date:covid[0].specimen_date,
              specimen_time:covid[0].specimen_Time,
              cnic:validated_cnic,
              list:[{
                title:test_name,
                reports:[{
                  result:covid[0].result,
                  test_name:test_name,
                  comments:"",
                  ref_range:"",
                  prev1: covid[0].prev_result_1.replace('\r','\n'),
                  prev2: covid[0].prev_result_2.replace('\r','\n'),
                  specimen_date:covid[0].specimen_date,
                  result_date:covid[0].result_date,
                  specimen_time:covid[0].specimen_Time,
                }],
                id:covid[0].login_id,
                password:covid[0].Password,
                e_mail:covid[0].e_mail,
                contact:response[0].PatientMobile,
                Airline:covid[0].air_line,
              }],
              toAddress:covid[0].toAddress,
              branch_address:covid[0].branch_address,
              result_date:covid[0].result_date,
              result_time:covid[0].result_time,
              user_id:covid[0].User_Name.toUpperCase()
            
            }
            BLOB.covid_record=covid_record
            console.log(BLOB)
            setReportdata(BLOB)
        })
      })
    })
   })
  }
  else if(Covid19AntiGen && !Covid19Flag){
    
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAntiGen).then(res => res.json()).then((covid) => {
  
        svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
          svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
            svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
              var test_name = covid[0].test_name
              if (covid[0].test_name == "") {
                test_name = "Covid-19 RNA by PCR"
              }
              var cnic = covid[0].cnic + ""
              var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
  
              covid_antigen = {
                barcode:barcode,
                 StringDOB:covid[0].StringDOB,
                invoice_id:covid[0].invoice_id,
                QR_Code_Online:QR_Code_Online,
                Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,
                QR_Code_Offline:QR_Code_Offline,
                Airline:covid[0].air_line,
                country:covid[0].country,
                dob:covid[0].dob,
                flight_no:covid[0].flight_no,
                flight_date:covid[0].flight_date,
                final_time:covid[0].final_time,
                ticket_no:covid[0].ticket_no,
                e_mail:covid[0].e_mail,
                contact:response[0].PatientMobile,
                name:covid[0].name,
                passport:covid[0].passport,
                ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                title:test_name,
                age_gender:covid[0].age_gender,
                specimen_date:covid[0].specimen_date,
                specimen_time:covid[0].specimen_Time,
                cnic:validated_cnic,
                list:[{
                  title:test_name,
                  reports:[{
                    result:covid[0].result,
                    test_name:test_name,
                    comments:"",
                    ref_range:"",
                    prev1: covid[0].prev_result_1.replace('\r','\n'),
                    prev2: covid[0].prev_result_2.replace('\r','\n'),
                    specimen_date:covid[0].specimen_date,
                    result_date:covid[0].result_date,
                    specimen_time:covid[0].specimen_Time,
                  }],
                  id:covid[0].login_id,
                  password:covid[0].Password,
                  e_mail:covid[0].e_mail,
                  contact:response[0].PatientMobile,
                  Airline:covid[0].air_line,
                }],
                toAddress:covid[0].toAddress,
                result_date:covid[0].result_date,
                branch_address:covid[0].branch_address,
                result_time:covid[0].result_time,
                user_id:covid[0].User_Name.toUpperCase()
              
              }
              BLOB.covid_antigen=covid_antigen
              console.log(BLOB)
              setReportdata(BLOB)
          })
        })
      })
     })
    }
    else if(Covid19AntiGen && Covid19Flag){
    
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAntiGen).then(res => res.json()).then((covid) => {
    
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
            svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
              svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                var test_name = covid[0].test_name
                if (covid[0].test_name == "") {
                  test_name = "Covid-19 RNA by PCR"
                }
                var cnic = covid[0].cnic + ""
                var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                covid_antigen = {
                  barcode:barcode,
                   StringDOB:covid[0].StringDOB,
                  invoice_id:covid[0].invoice_id,
                  QR_Code_Online:QR_Code_Online,
                  Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                  Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,
                  QR_Code_Offline:QR_Code_Offline,
                  Airline:covid[0].air_line,
                  country:covid[0].country,
                  dob:covid[0].dob,
                  flight_no:covid[0].flight_no,
                  flight_date:covid[0].flight_date,
                  final_time:covid[0].final_time,
                  ticket_no:covid[0].ticket_no,
                  e_mail:covid[0].e_mail,
                  contact:response[0].PatientMobile,
                  name:covid[0].name,
                  passport:covid[0].passport,
                  ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                  title:test_name,
                  age_gender:covid[0].age_gender,
                  specimen_date:covid[0].specimen_date,
                  specimen_time:covid[0].specimen_Time,
                  cnic:validated_cnic,
                  list:[{
                    title:test_name,
                    reports:[{
                      result:covid[0].result,
                      test_name:test_name,
                      comments:"",
                      ref_range:"",
                      prev1: covid[0].prev_result_1.replace('\r','\n'),
                      prev2: covid[0].prev_result_2.replace('\r','\n'),
                      specimen_date:covid[0].specimen_date,
                      result_date:covid[0].result_date,
                      specimen_time:covid[0].specimen_Time,
                    }],
                    id:covid[0].login_id,
                    password:covid[0].Password,
                    e_mail:covid[0].e_mail,
                    contact:response[0].PatientMobile,
                    Airline:covid[0].air_line,
                  }],
                  toAddress:covid[0].toAddress,
                  branch_address:covid[0].branch_address,
                  result_date:covid[0].result_date,
                  result_time:covid[0].result_time,
                  user_id:covid[0].User_Name.toUpperCase()
                
                }
                BLOB.covid_antigen=covid_antigen
              
                fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + toPrint.covidAux).then(res => res.json()).then((covid) => {
    
          svgString2Image(covid[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
            svgString2Image(covid[0].barcode, 800, 600, 'png', (barcode) => {
              svgString2Image(covid[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                var test_name = covid[0].test_name
                if (covid[0].test_name == "") {
                  test_name = "Covid-19 RNA by PCR"
                }
                var cnic = covid[0].cnic + ""
                var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                covid_record = {
                  barcode:barcode,
                   StringDOB:covid[0].StringDOB,
                  invoice_id:covid[0].invoice_id,
                  Base64_PicturePatient:"data:image/png;base64,"+covid[0].Base64_PicturePatient,
                  Base64_ImageSampleTaking:"data:image/png;base64,"+covid[0].Base64_ImageSampleTaking,

              equipment_used_1:covid[0].equipment_used_1,
              equipment_used_2:covid[0].equipment_used_2,
              regent_kits_1:covid[0].regent_kits_1,
              regent_kits_2:covid[0].regent_kits_2,
              batch_number_1:covid[0].batch_number_1,
              batch_number_2:covid[0].batch_number_2,

                  QR_Code_Online:QR_Code_Online,
                  QR_Code_Offline:QR_Code_Offline,
                  Airline:covid[0].air_line,
                  country:covid[0].country,
                  dob:covid[0].dob,
                  flight_no:covid[0].flight_no,
                  flight_date:covid[0].flight_date,
                  final_time:covid[0].final_time,
                  ticket_no:covid[0].ticket_no,
                  e_mail:covid[0].e_mail,
                  contact:covid[0].PatientMobile,
                  name:covid[0].name,
                  passport:covid[0].passport,
                  ShouldSampleDateTimePrint:covid[0].ShouldSampleDateTimePrint,
                  title:test_name,
                  age_gender:covid[0].age_gender,
                  specimen_date:covid[0].specimen_date,
                  specimen_time:covid[0].specimen_Time,
                  cnic:validated_cnic,
                  list:[{
                    title:test_name,
                    reports:[{
                      result:covid[0].result,
                      test_name:test_name,
                      comments:"",
                      ref_range:"",
                      prev1: covid[0].prev_result_1.replace('\r','\n'),
                      prev2: covid[0].prev_result_2.replace('\r','\n'),
                      specimen_date:covid[0].specimen_date,
                      result_date:covid[0].result_date,
                      specimen_time:covid[0].specimen_Time,
                    }],
                    id:covid[0].login_id,
                    password:covid[0].Password,
                    e_mail:covid[0].e_mail,
                    contact:response[0].PatientMobile,
                    Airline:covid[0].air_line,
                  }],
                  toAddress:covid[0].toAddress,
                  result_date:covid[0].result_date,
                  branch_address:covid[0].branch_address,
                  result_time:covid[0].result_time,
                  user_id:covid[0].User_Name.toUpperCase()
                
                }
                BLOB.covid_record=covid_record
                console.log(BLOB)
                setReportdata(BLOB)
            })
          })
        })
       })
               
            })
          })
        })
       })
      }else{
        setReportdata(BLOB)

      }
    
  
  
},800)

   })


  })
}

  var svgString2Image = (svgString, width, height, format, callback) => {

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  return (

    <Paper className={classes.root}
      style={{
        flexDirection: "column",
        textAlign: "center"
      }}
    >
      <div style={{ width: "100%" , marginTop:"1em"}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            search_patient()
          }} >
            <TextField
              id="outlined-required"
              label="Patient ID"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
              variant="outlined"
            />
            <input type="submit" style={{
              position: 'absolute',
              left: '-9999px'
            }} />
         
            <TextField
              id="outlined-required"
              label="Patient Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
            />
            <TextField
              id="outlined-required"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              label="Patient Mobile"
              variant="outlined"
            />
             <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" , marginLeft:"2em"}}
            onClick={() => {
              search_patient();
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em", marginLeft:"1em" }}
            onClick={() => {
              setId("");
              setMobile("");
              setName("");
            }}
            className={classes.button}
          >
            Clear
          </Button>

          </form>
        {
          id.trim()!="" ?
          <Button
          variant="contained"
          style={{ marginTop: "1em" , marginLeft:"2em", backgroundColor:"yellow"}}
          onClick={() => {
            var obj={
              patient_id:id,
              branch_id:parseInt(localStorage.getItem('branch_id')),
              panel_id:localStorage.getItem('panel_code')
            }
            fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortalCallingPatientInvoices", {
              method: "POST",
              body: JSON.stringify(obj),
            })
              .then((res) => res.json())
              .then((response)=>{
                var InvoiceBox=[]
                response.map((temp)=>{
                  var obj={
                    invoice_no:temp.invoice_no,
                    date:new Date(temp.invoice_date),
                    select:(<Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => {
                        setInvoiceID(temp.invoice_no)
                        setOpenInvoice(false)
                      }}
                    >
                      Select
                    </Button>)
                  }
                  InvoiceBox.push(obj)

                })
                const sortedData = InvoiceBox.sort((a, b) =>  new Date(b.date) - new Date(a.date));
                setSearch_table(sortedData)
               setOpenInvoice(true)
              })
              
          }}
          className={classes.button}
        >
        Call Invoices
        </Button>
        :
        null
          
        }
          </div>
      
      <div style={{
        flexDirection: "row",
        flex: 1,
        textAlign: 'center',
        padding: "2em"
      }}>
        
        <TextField
          required
          id="outlined-required"
          label="Invoice No"
          variant="outlined"
          value={invoiceID}
          onChange={(e) => {
            setRows([])
            setPrintiDicator(false)
            setInvoiceID(e.target.value)
            // 9-march
            setReportVisibilitynew(false)

          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            margin: '1em'
          }}
          onClick={() => {
            // 9-march
            setcountdown(0)
            var obj = {
              invoice_no: invoiceID,
              branch_id:parseInt(localStorage.getItem('branch_id')),
              panel_id:localStorage.getItem('panel_code')
            }
             fetch("https://reports.mpl-labs.pk:8443/4DACTION/Web_ReportsPrintingListCallup", {
              // fetch("http://202.142.169.174:8090/4DACTION/Web_ReportsPrintingListCallup", {
           
              method: "POST",
              body: JSON.stringify(obj)
            }).then(res => res.json()).then((response) => {
              console.log("Web_ReportsPrintingListCallup"+JSON.stringify(response))
              if (response[0].ArrayAuxID == "[]") {
                setInvalid(true)
                return
              }
              setInvalid(false)
              var data = response[0]

              var collectionBox = []

              var counter = 0

              var month= ["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]
              var counterData=0

              JSON.parse(data.ArrayAuxID).map((gRidRow) => {
                
                if(JSON.parse(data.ArrayCallsBlobPDF)[counter]){
                  window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + JSON.parse(data.ArrayAuxID)[counter] )
                  counterData=1
                  return
                }
                var temp = {
                  AuxId: gRidRow,
                  patientID: JSON.parse(data.ArrayPatientID)[counter],
                  id: JSON.parse(data.ArrayInvoiceNo)[counter],
                  date: new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getDate() + "-" +(month[new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getMonth()])+ "-" + new Date(JSON.parse(data.ArrayInvoiceDate)[counter]).getFullYear(),
                  name: JSON.parse(data.ArrayPatientName)[counter],
                  mobile: JSON.parse(data.ArrayPatientMobile)[counter],
                  test_code: JSON.parse(data.ArrayTestCode)[counter],
                  test_name: JSON.parse(data.ArrayTestName)[counter],
                  department: JSON.parse(data.ArrayDepartmentID)[counter],
                  nature: JSON.parse(data.ArrayTestNature)[counter],
                  departmentName: JSON.parse(data.ArrayDepartmentName)[counter],
                  alloWprint:!JSON.parse(data.ArrayPrintingNOTAllow)[counter],
                  DeliveryStatus:JSON.parse(data.ArrayRepDeliverStatus)[counter] ? <Check color="green"/> : <Close color="error" />,

                  // For print Image
                  groupId: JSON.parse(data.ArrayGroupID)[counter], 
                }
                counter++
                collectionBox.push(temp)
              })
              if(counterData==0){
                setRows(collectionBox)
                setToprint({})
                setPrintiDicator(false)
              }else{

              }
            })

          }}
        >Search</Button>
         {rows.length!==0?
          <>
          <ReactWhatsapp  style={{border:"none",cursor:"pointer",alignSelf:"center",position:"absolute",background:"white"}} number={rows[0].mobile.replace("0","+92")} message="" >
           
           <WhatsAppIcon  size="large" style={{color:"green",height:50,width:50}}></WhatsAppIcon>
         </ReactWhatsapp>
 
{/*       
          <ReactWhatsapp  style={{border:"none",background:"white",cursor:"pointer"}} number={rows[0].mobile.replace("0","+92")} message="" >
          
            <WhatsAppIcon style={{color:"green"}}></WhatsAppIcon>
          </ReactWhatsapp> */}
          </>:<></>}
      </div>
      {
        Invalid ?
          <p style={{
            textAlign: "center",
            color: "red"
          }}>Invalid Invoice No</p>
          : null
      }
      {
        printIndicator ?
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PrintIcon fontSize={"large"} />}
            onClick={() => {
              if(toPrint=={}){
                alert("Please Select Tests!!")
                return
              }
              passOrderToPrint()
            }}

          >Print Preview</Button>
          :
          null
      }
       {
        printIndicator ?
          <Button
          style={{marginLeft:"2%"}}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<Check fontSize={"large"} />}
            onClick={() => {
              var aux_id=rows.map((row)=>(row.AuxId))
               fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebRepPrintDeliveryStatusUpdate",{
                method:"POST",
                'Content-Type':"application/json",
                body: JSON.stringify({
                  "aux_id":aux_id,
                  'user_id':localStorage.getItem("user_id")
                })
              }).then(res=>res.json()).then((res)=>{
                if(res.DeliveryUpdateStatus=="Successful"){
                  alert("Reports Successfully Delivered")
                  window.location.reload()
                }else{
                  alert("Sorry Failed!")

                }
              })

            }}

          >Deliver Reports</Button>
          :
          null
      }

      <MaterialTable
        title="Report Printing"
        data={rows}
        columns={[
          { title: 'Invoice ID', field: 'id' },
          { title: 'Patient ID', field: 'patientID' },
          {title: 'Allow Print', field:"alloWprint", type:"boolean"},
          { title: 'Department', field: 'departmentName', cellStyle: {
            backgroundColor: '#039be5',
            color: '#FFF'
          } },
          { title: 'Date', field: 'date' },
          { title: 'Name', field: 'name' },
          { title: 'Mobile', field: 'mobile' },
          { title: 'Test Code', field: 'test_code' },
          { title: 'Test Name', field: 'test_name' },
          { title: 'Delivery Status', field: 'DeliveryStatus' }

        ]}
        options={{
          selection: true,
          paging:false,
          minBodyHeight:50,
          showSelectAllCheckbox:SelectionControl
        }}

        onSelectionChange={(orderToPrint) => {
          if (orderToPrint.length == 0) {
            setPrintiDicator(false)
            return
          }
          setPrintiDicator(true)
          
          
          var inVoice=[]
          var AuXiD=[]
          var departments=[]
          var date=[]
          var covidAux=""
          var covidAntiGen=""
        
          var departNames=[]
          var AllowPrint=[]
          var natures=[]
            // for print image
            var groupIdfrPic=[]
              // 21-oct-2024 specail Reports WES & NIPT
          var TestCode=[]
          var PatientId=[]

          orderToPrint.map((item)=>{
            AllowPrint.push(item.alloWprint)
            AuXiD.push(item.AuxId)
            inVoice.push(item.id+"")
            departments.push(item.department)
            date.push(item.date)
            departNames.push(item.departmentName)
            natures.push(item.nature)
                // for print image
                groupIdfrPic.push(item.groupId)
                 // 21-oct-2024 specail Reports WES & NIPT
                 TestCode.push(item.test_code)
                 PatientId.push(item.patientID)

            if(item.test_code=="COVIDPCR"){
              covidAux=item.AuxId
            }
            if(item.test_code=="COVAG"){
              covidAntiGen=item.AuxId
            }
          })
          
          setToprint({
            AllowPrint:AllowPrint,
            inVoiceID:inVoice,
            auXiD:AuXiD,
            natures:natures,
            department:departments,
            date:date,
            departNames:departNames,
            covidAux:covidAux,
            covidAntiGen:covidAntiGen,
             //for printing image
             groupIdPicDisp:groupIdfrPic,
             //  21-oct-2024 specail Reports WES & NIPT
            testCode:TestCode,
            patientID:PatientId
          })
        }}
      />
              {/* 9-march */}
              <div  >
         {reportVisibilitynew && 
          <Line  ref={chartRef} data={chartData} options={optionsn} />

    }
    </div>
      {/* Invoices Column */}
      <Modal
        open={openInvoice}
        onClose={()=>{
          setOpenInvoice(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
        <div style={{
         width:"70%",
         marginLeft:"15%",
         marginRight:"15%"
       }}>
          <MaterialTable
            columns={[
              { field: "invoice_no", title: "Invoice ID" },
              { field: "date", title: "Invoice Date", type:"date"},
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Invoice Record"}
          />
          </div>
        </div>
      </Modal>
      
      {/* Patient Selection */}
     
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
        <div style={{
         width:"70%",
         marginLeft:"15%",
         marginRight:"15%"
       }}>
          <MaterialTable
            columns={[
              { field: "Patient_ID", title: "ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Name" },
              { field: "Age", title: "Age" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "cnic", title: "CNIC" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient Record"}
          />
          </div>
        </div>
      </Modal>
      
       <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={reportVisibility}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
         setReportdata({
          reports: []
         })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h1 className={classes.modalTitle}><b>Report</b></h1>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {/* 9-march */}
          {
            reportData.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer >
                
                    <PDFViewer width="1000" height="1000" className="app" >
                      <Report invoice={reportData} pic={chartImage} countdown={countdown}  />
                    </PDFViewer>
         
                 
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setReportVisibility(false)
              setReportdata({
                reports: []
               })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
       {/* 21-oct-2024 specail Reports WES & NIPT */}
       <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        // fullScreen
        maxWidth={'xl'}
        fullWidth={true}
        open={reportVisibilitySpecialTest}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
         setReportdata({
          reports: []
         })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
          // 18-oct-2024 auto send whatsapp report
          style={{display:"flex",justifyContent:"space-between",alignItems:"center",flexDirection:"row"}}
        >

          <h1 className={classes.modalTitle}><b>Special Report</b></h1>
          {/* <button onClick={()=>{
            generatePdf()
          }}> Send PDF via Whatsapp</button> */}
          {/* // 18-oct-2024 auto send whatsapp report */}
            {/* <Button
          variant="contained"
          style={{backgroundColor:"green"}}
          color="primary"
          size="large"
          startIcon={<WhatsAppIcon fontSize={"large"} />}
          onClick={() => {
            if(toPrint=={}){
              alert("Please Select Tests!!")
              return
            }
            generatePdfForSpecialReport()
          }}

        >Send Whatsapp </Button> */}
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
       
                
                <iframe
    src={`https://reports.mpl-labs.pk:8443/4DACTION/WebServerReportingFSsoft?AuxID=${toPrint?.auXiD}&PatientID=${toPrint?.patientID}&InvoiceNo=${toPrint?.inVoiceID}`}
    title="PDF Viewer"
    width="100%"
    height="1000px"
    style={{ border: 'none' }}
  />
         
{/*                  
                </GridContainer>

              </Fragment>
              </center> */}
       
         
    
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setreportVisibilitySpecialTest(false)
              setReportdata({
                reports: []
               })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
    </Paper>

  )
}
